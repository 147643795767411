var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-page"
  }, [_c('div', {
    staticClass: "card-page"
  }, [_c('div', {
    staticClass: "img-banner-bg h-100"
  }, [_c('div', {
    staticClass: "center-content success-message h-100"
  }, [_c('img', {
    staticClass: "mb-2",
    attrs: {
      "src": require("../assets/img/icon-pay-failed.png")
    }
  }), _c('p', {
    staticClass: "font-size-24 font-weight-700 text-white text-center mb-2"
  }, [_vm._v(_vm._s(_vm.title))]), _c('p', {
    staticClass: "font-size-14 font-weight-400 text-white text-center me-3 ms-3"
  }, [_vm._v(_vm._s(_vm.message))])]), _vm.payment_type == '1' ? _c('div', {
    staticClass: "footer-form"
  }, [_c('button', {
    staticClass: "btn btn-white btn-payment w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.backToHome();
      }
    }
  }, [_c('span', {
    staticClass: "text-primary"
  }, [_vm._v("Kembali ke Event")])])]) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }