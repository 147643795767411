var render = function () {
  var _vm$error, _vm$error2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-page"
  }, [_vm.status_payment == 'waiting' ? _c('HeaderComp', {
    attrs: {
      "title": "",
      "url": "#",
      "noback": "",
      "isBackToHome": ""
    }
  }) : _vm._e(), _c('div', {
    staticClass: "card-page"
  }, [_c('div', {
    staticClass: "form-order"
  }, [_c('div', {
    class: ['title-page mb-3', {
      'mt-3': _vm.status_payment == 'pending'
    }]
  }, [_vm._v("Pembayaran")]), _c('div', {
    staticClass: "card-shadow mb-3"
  }, [_c('div', {
    staticClass: "content-space-between event-calendar"
  }, [_vm._m(0), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.formatDateTimesDay(_vm.eventDate)))])]), _c('div', {
    staticClass: "title-event content-space-between"
  }, [_vm.imgBanner ? _c('img', {
    staticClass: "banner-small me-3",
    attrs: {
      "src": _vm.imgBanner
    }
  }) : _vm._e(), _vm._v(" "), _c('span', {
    staticClass: "w-100"
  }, [_vm._v(_vm._s(_vm.eventName))])]), _c('div', {
    staticClass: "content-space-between"
  }, [_c('p', {
    staticClass: "m-0 text-black font-size-12"
  }, [_vm._v("Status Pembayaran")]), _c('p', {
    staticClass: "m-0"
  }, [_c('span', {
    staticClass: "badges-timer bg-tertiery m-0"
  }, [_vm._v(_vm._s(_vm.status_order))])])])]), _c('div', {
    staticClass: "card-shadow-total mb-3"
  }, [_c('div', {
    staticClass: "container-ticket"
  }, [_c('img', {
    staticClass: "icon-12 me-1",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-ticket-grey.png")
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "font-weight-600"
  }, [_vm._v("Tiket Kamu")]), _c('div', {
    staticClass: "mt-2"
  }, _vm._l(_vm.groupedOrderSummary, function (item, key) {
    return _c('div', {
      key: key
    }, [_c('div', {
      staticClass: "sub-total-ticket"
    }, [_c('span', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.category_name))]), _c('span', [_vm._v(_vm._s(_vm.formatCurrency((item === null || item === void 0 ? void 0 : item.sum_price) / (item === null || item === void 0 ? void 0 : item.sum_quantity))))])]), _c('div', {
      staticClass: "total-ticket mb-1"
    }, [_c('span', {
      staticClass: "font-weight-700 font-size-12"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.sum_quantity) + "x " + _vm._s(item === null || item === void 0 ? void 0 : item.category_name))]), _c('span', {
      staticClass: "font-weight-700 font-size-12"
    }, [_vm._v(_vm._s(_vm.formatCurrency(item === null || item === void 0 ? void 0 : item.sum_price)))])])]);
  }), 0), _c('div', {
    staticClass: "total-ticket"
  }, [_c('span', {
    staticClass: "font-weight-400 font-size-12"
  }, [_vm._v("Biaya Layanan")]), _c('span', {
    staticClass: "font-weight-600 font-size-12"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.admin_fee)))])]), _c('div', {
    staticClass: "total-ticket admin-fee mb-3"
  }, [_c('span', {
    staticClass: "font-weight-400 font-size-12"
  }, [_vm._v("Diskon")]), _c('span', {
    staticClass: "font-weight-600 font-size-12"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.discount)))])]), _c('div', {
    staticClass: "summary-qty mt-2"
  }, [_c('span', [_vm._v("Total Pembayaran")]), _c('span', {
    staticClass: "font-weight-600"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.total_price)))])])])]), _vm.message_order ? _c('div', {
    staticClass: "card-disclaimer content-space-between mb-2"
  }, [_c('img', {
    staticClass: "icon-12 me-1",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-hourglass-end.png")
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "font-size-10 font-weight-400 text-black w-100"
  }, [_vm._v(_vm._s(_vm.message_order))])]) : _vm._e()])]), _c('Loading', {
    attrs: {
      "isLoading": _vm.loading
    }
  }), _c('b-modal', {
    ref: "failed-modal",
    attrs: {
      "size": "sm",
      "id": "failed-modal",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "warning-icon mb-2",
    attrs: {
      "src": require("../assets/img/warning-icon.png")
    }
  }), _c('p', {
    staticClass: "font-size-20 font-weight-600 mb-2"
  }, [_vm._v(_vm._s((_vm$error = _vm.error) === null || _vm$error === void 0 ? void 0 : _vm$error.title))]), _c('p', {
    staticClass: "font-size-12 font-weight-400 mb-5"
  }, [_vm._v(_vm._s((_vm$error2 = _vm.error) === null || _vm$error2 === void 0 ? void 0 : _vm$error2.message))]), _c('button', {
    staticClass: "btn btn-primary btn-sm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('failed-modal');
      }
    }
  }, [_vm._v("Ok, Terima Kasih")])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('img', {
    staticClass: "me-2 icon-12",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-calendar-grey.png")
    }
  }), _c('span', [_vm._v("Tanggal Event")])]);
}]

export { render, staticRenderFns }