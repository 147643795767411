<template>
    <div class="container-page">
        <!-- <HeaderComp title="" url="#" noback isBackToHome /> -->
        <div class="card-page">
            <div class="img-banner-bg h-100">
                <div class="center-content success-message h-100">
                    <img src="../assets/img/icon-pay-failed.png" class="mb-2">
                    <p class="font-size-24 font-weight-700 text-white text-center mb-2">{{ title }}</p>
                    <p class="font-size-14 font-weight-400 text-white text-center me-3 ms-3">{{ message }}</p>
                </div>
                <div class="footer-form" v-if="payment_type == '1'">
                    <button class="btn btn-white btn-payment w-100" type="button" @click="backToHome()" >
                        <span class="text-primary">Kembali ke Event</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from "../components/Loading.vue";
// import axios from "axios";
import HeaderComp from "../components/HeaderComp.vue";
export default {
    components: {
        Loading,
        HeaderComp,
    },
    data() {
        return {
            showDetail: false,
            isDisabledRef: false,
            isLoading: false,
            message: localStorage.getItem('message_failed_payment'),
            title: localStorage.getItem('title_failed_payment'),
            payment_type : ""
        };
    },
    methods: {
        backToHome() {
            // const platform_key = localStorage.getItem('platform_key');
            // const token = localStorage.setItem('token', token);
            // if (platform_key == 'MGO_IOS' || platform_key == 'MGO_ANDROID') {
            //     var userAgent = navigator.userAgent || navigator.vendor || window.opera;
            //     if (/android/i.test(userAgent)) {
            //         JSBridge.deleteToken(token); // eslint-disable-line
            //     } else {
            //         window.webkit.messageHandlers.deleteToken.postMessage(token);
            //     }
            // }
            const base_url_event = localStorage.getItem('base_url_event');
            localStorage.clear();
            window.location.href = base_url_event;
        }
    },
    computed: {

    },
    mounted() {
        if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            this.payment_type = "1";
        }else{
            this.payment_type = this.$route.query.type;
        }
    },
};
</script>

<style lang="scss" scoped>
.btn-payment{
    padding: 4% 6%;
}

.container-page{
    max-width: 480px !important;
    margin: auto !important;
    width: 100% !important;
}

.disabled-qty {
    color: #647179 !important;
    cursor: not-allowed;
}

.rounded-footer {
    border-radius: 16px 16px 0px 0px !important;
}

.message-claimed {
    display: flex;
    justify-content: space-between;
    font-size: 0.625rem;
}

.message-claimed img {
    width: 3.59%;
    height: 3.59%;
}

.message-claimed span {
    font-size: 0.625rem;
}

.total-ticket {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: #000000;
    font-weight: 400;
}

.container-ticket i {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    display: inline-block;
}

.container-ticket {
    padding: 2%;
    font-size: 0.75rem;
}

.card-summary {
    padding: 2%;
    border: 1px solid #E7ECF4;
    border-radius: 8px;
}

.summary-detail {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
}

.summary-detail i {
    font-size: 1rem;
    color: #8E8E93;
}

.summary-qty {
    display: flex;
    justify-content: space-between;
    font-size: 0.85rem;
}

.link-text {
    text-decoration: underline;
}

.disclaimer-text {
    padding: 2%;
    font-size: 0.625rem;
}

.text-qty {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: -0.5px;
    text-align: center;
    display: inline-flex;
}

.icon-arrow {
    font-size: 0.75rem;
    line-height: 12px;
    align-self: center;
}

.icon-qty {
    margin-top: 3px;
    font-size: 0.75rem;
    line-height: 13px;
    letter-spacing: -0.5px;
    display: inline-flex;
}

.ticket-qty {
    font-size: 0.75rem;
    align-self: center;
}

.ticket-detail-name {
    font-size: 0.75rem;
}

.ticket-detail-number {
    font-size: 0.625rem;
}

.cat-ticket {
    border: 1px solid #F2F5F7;
    padding: 2%;
    display: flex;
    justify-content: space-between;
}

.sub-sub-title {
    border-bottom: none !important;
    font-size: 0.625rem;
    /* font-weight: bold;
    margin: 0 0 30px; */
    cursor: pointer;
}

.sub-sub-title span {
    vertical-align: middle;
}

.header-title {
    padding: 2% 2%;
    background: #F2F5F7;
    border-radius: 4px;
}

.desc-card {
    background: #F2F5F7;
    font-size: 0.625rem;
    border-radius: 8px;
    padding: 8px;
}

.desc-title {
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 16px;
}

.address-redirect {
    font-size: 16px;
}

.address-detail {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 75%;
    line-height: 14.52px;
    font-size: 0.75rem;
    font-weight: 600;
    white-space: nowrap;
}

.address-city {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 75%;
    color: #8E8E93;
    line-height: 14.52px;
    font-size: 0.75rem;
    font-weight: 400;
    white-space: nowrap;
}

.maps-img img {
    width: 32px;
    height: 32px;
    border-radius: 8px;
}

.title-event {
    font-size: 1rem;
    font-weight: 600;
}

.event-calendar {
    font-size: 0.75rem;
}

.event-calendar span {
    font-weight: 400;
}

.img-banner-bg {
    background-image: url("../assets/img/bg-success.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.success-message {
    padding-left: 15%;
    padding-right: 15%;
}

.success-message img {
    width: 74px;
    height: 74px;
}

.container-content {
    background-color: white;
    padding: 4%;
    margin-top: 49%;
    border-radius: 16px 16px 0px 0px;
}

.card-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.footer-form {
    width: 100%;
    padding: 4% 4%;
}
</style>