<template>
    <div class="container-page">
        <HeaderComp title="" url="/check-ticket" />
        <div class="card-page img-banner-bg bg-primary h-100">
            <div class="">
                <div class="text-center font-size-20 font-weight-400 title-ticket mb-2">
                    <p class="m-0">{{detail?.event_name}}</p>
                </div>
                <div class="card-ticket h-100">
                    <div class="ticket-identity">
                        <div class="mb-2">
                            <p class="m-0 cat-idt">Nama Pengunjung</p>
                            <p class="m-0 font-size-14 font-weight-600">{{detail?.ticket_attendee_name}}</p>
                        </div>
                        <div class="mb-2">
                            <p class="m-0 cat-idt">Tiket Kategori</p>
                            <p class="m-0 font-size-14 font-weight-600">{{detail?.ticket_category_name}}</p>
                        </div>
                        <div class="mb-2">
                            <p class="m-0 cat-idt">Event Schedule</p>
                            <p class="m-0 font-size-14 font-weight-600">{{ detail?.event_start_date }}</p>
                        </div>
                        <div class="mb-3">
                            <p class="m-0 cat-idt">Lokasi</p>
                            <p class="m-0 font-size-12 font-weight-600">{{detail?.event_address}}</p>
                        </div>
                    </div>
                    <div class="center-content text-center fr-container h-100">
                        <div class="font-size-12 font-weight-600 text-black">Face Recognition</div>
                        <div class="font-size-12 font-weight-400 text-black mb-2">Upload foto selfie kamu, untuk check
                            in menggunakan Face Recognition</div>
                        <button type="button" class="btn btn-sukha-outline btn-xsm mb-2"
                            @click="uploadFr()">Upload Face Recognition</button>
                        <div class="font-size-12 font-weight-400 text-black mb-2">Atau</div>
                        <div class="font-size-12 font-weight-600 text-black">Scan QR</div>
                        <div class="font-size-12 font-weight-400 text-black mb-3">Scan QR berikut untuk melakukan Check
                            In</div>
                        <img class="img-qr" :src="detail?.qr_code_string">
                        <div class="font-size-12 font-weight-400 text-black mt-3">Nomor Tiket</div>
                        <div class="font-size-16 font-weight-600 text-black">{{detail?.ticket_code}}</div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal size="sm" ref="failed-modal" id="failed-modal" centered hide-footer hide-header no-close-on-backdrop
            no-close-on-esc>
            <div class="text-center">
                <img src="../assets/img/warning-icon.png" class="warning-icon mb-2">
                <p class="font-size-20 font-weight-600 mb-2">{{ error?.title }}</p>
                <p class="font-size-12 font-weight-400 mb-5">{{ error?.message }}</p>
                <button type="button" class="btn btn-primary btn-sm" @click="$bvModal.hide('failed-modal')">Ok, Terima
                    Kasih</button>
            </div>
        </b-modal>
        <Loading :isLoading="loading" />
    </div>
</template>

<script>
import Loading from "../components/Loading.vue";
import axios from "axios";
import HeaderComp from "../components/HeaderComp.vue";
export default {
    components: {
        Loading,
        HeaderComp
    },
    data() {
        return {
            showDetail: false,
            isDisabledRef: false,
            loading: false,
            detail : null,
            error: {
                title: "",
                message: ""
            }
        };
    },
    methods: {
        getDetail() {
            this.loading = true;
            axios.get("/ticket/detail").then((res) => {
                this.detail = res?.data?.data;
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                if (error?.response?.data?.title || error?.response?.data?.message) {
                    this.error.title = error?.response?.data?.title;
                    this.error.message = error?.response?.data?.message;
                    this.$bvModal.show('failed-modal');
                } else {
                    this.$router.push('/busy-server');
                }
            });
        },
        uploadFr(){
            localStorage.setItem("lastPathData","e-ticket")
            this.$router.push('/upload-fr')
        }
    },
    computed: {

    },
    mounted() {
        this.getDetail();
        localStorage.setItem('currentPath', '/e-ticket');
    },
};
</script>

<style lang="scss" scoped>
.img-qr{
    width: 165px;
    height: 165px;
}

.fr-container {
    padding-left: 4vw;
    padding-right: 4vw;
}

.ticket-identity {
    border-bottom: 1px dashed #D2D7DA
}

.cat-idt {
    color: #647179;
    font-size: 0.75rem;
    font-weight: 400;
}

.card-ticket {
    background-color: #ffffff;
    padding: 4vw;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title-ticket {
    color: #F2F5F7;
    font-family: 'Arial Rounded MT', Arial, sans-serif !important;
    letter-spacing: 0.5px;
}

.img-banner-bg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4vw;
}

.success-message {
    padding-left: 15vw;
    padding-right: 15vw;
}

.success-message img {
    width: 19vw;
    height: 19vw;
}

.card-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
</style>