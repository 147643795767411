var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-upload-field",
    on: {
      "dragover": _vm.onDragover,
      "drop": _vm.onDrop
    }
  }, [_vm.dragover ? _c('div', {
    staticClass: "upload-box",
    on: {
      "dragleave": _vm.onDragleave
    }
  }, [_c('div', {
    staticClass: "upload-preview"
  }, [_c('span', {
    staticClass: "font-size-xsmall text-default font-bold text-center"
  }, [_vm._v(_vm._s(_vm.$t("infaq.dropHere")))])])]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.dragover,
      expression: "!dragover"
    }],
    staticClass: "upload-box cursor-pointer",
    on: {
      "click": function ($event) {
        return _vm.pickFile();
      }
    }
  }, [_vm.displayed ? _c('div', {
    staticClass: "upload-preview"
  }, [_c('div', {
    staticClass: "container-image",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.showModal('video-modal');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.displayed,
      "alt": _vm.label
    }
  }), _c('button', {
    staticClass: "btn bg-secondary text-white font-size-small",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.removeFile();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("infaq.remove")) + " ")])])]) : _vm._e(), (_vm.image || _vm.imageView) && _vm.isImage ? _c('div', {
    staticClass: "upload-preview"
  }, [_c('div', {
    staticClass: "container-image",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.showModal('video-modal');
      }
    }
  }, [_vm.imageView == null ? _c('img', {
    attrs: {
      "src": _vm.display,
      "alt": _vm.label
    }
  }) : _c('img', {
    attrs: {
      "src": _vm.viewImage(_vm.imageView),
      "alt": _vm.label
    }
  }), _c('button', {
    staticClass: "btn bg-secondary text-white font-size-small",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.removeFile();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("infaq.remove")) + " ")])])]) : _vm._e(), (_vm.image || _vm.imageView) && _vm.isVideo ? _c('div', {
    staticClass: "upload-preview"
  }, [_c('div', {
    staticClass: "container-image"
  }, [_vm.imageView == null ? _c('video', {
    attrs: {
      "src": _vm.viewImage(_vm.image, true),
      "alt": _vm.label
    }
  }) : _c('video', {
    attrs: {
      "src": _vm.viewImage(_vm.imageView, true),
      "alt": _vm.label
    }
  }), _c('div', {
    staticClass: "overlay-player cursor-pointer",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.showModal('video-modal');
      }
    }
  }, [_c('div', {
    staticClass: "player-icon"
  }, [_c('play-circle-icon', {
    staticClass: "text-default",
    attrs: {
      "size": "3x"
    }
  })], 1)]), _c('button', {
    staticClass: "btn bg-secondary text-white font-size-small",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.removeFile();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("infaq.remove")) + " ")])])]) : _vm._e(), !_vm.isImage && !_vm.isVideo && _vm.value ? _c('div', {
    staticClass: "upload-preview files"
  }, [_c('div', {
    staticClass: "container-image"
  }, [_c('div', {
    staticClass: "\n                            font-size-xsmall\n                            text-dark\n                            font-bold\n                            text-center\n                            center-content\n                          "
  }, [_vm._v(" " + _vm._s(_vm.filename) + " ")]), _c('button', {
    staticClass: "btn bg-secondary text-white font-size-small",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.removeFile();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("infaq.remove")) + " ")])])]) : _vm._e(), !_vm.filename && !_vm.image && !_vm.displayed ? _c('div', {
    staticClass: "upload-empty"
  }, [_c('span', {
    staticClass: "font-size-xsmall text-default font-bold text-center"
  }, [_vm._v(_vm._s(_vm.$t("infaq.filePlaceholder")))])]) : _vm._e()]), _c('input', {
    ref: "input",
    staticClass: "file",
    attrs: {
      "type": "file",
      "disabled": _vm.disabled
    },
    on: {
      "change": _vm.processFile
    }
  }), _c('b-modal', {
    ref: "video-modal",
    attrs: {
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', [_c('div', {
    staticClass: "container-video"
  }, [_vm.isVideo ? _c('video', {
    attrs: {
      "width": "100%",
      "controls": ""
    }
  }, [_vm.imageView == null ? _c('source', {
    attrs: {
      "src": _vm.image,
      "type": "video/mp4"
    }
  }) : _vm._e(), _c('source', {
    attrs: {
      "src": _vm.viewImage(_vm.imageView, true),
      "type": "video/mp4"
    }
  }), _vm._v(" " + _vm._s(_vm.$t("infaq.browserInfo")) + ". ")]) : _vm._e(), _vm.isImage ? _c('div', [_vm.imageView == null ? _c('img', {
    staticClass: "center-content view-image",
    attrs: {
      "src": _vm.image,
      "alt": _vm.label
    }
  }) : _c('img', {
    staticClass: "center-content view-image",
    attrs: {
      "src": _vm.viewImage(_vm.imageView),
      "alt": _vm.label
    }
  })]) : _vm._e(), _c('button', {
    staticClass: "btn btn-secondary p-0",
    on: {
      "click": function ($event) {
        return _vm.hideModal('video-modal');
      }
    }
  }, [_vm._v(" X ")])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }