var render = function () {
  var _vm$error, _vm$error2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-page"
  }, [_c('HeaderComp', {
    attrs: {
      "title": "",
      "url": "/detail-order",
      "isConfirmOrder": ""
    }
  }), _c('div', {
    staticClass: "card-page"
  }, [_c('div', {
    staticClass: "form-order"
  }, [_c('div', {
    staticClass: "title-page mb-3"
  }, [_vm._v("Pembayaran")]), _c('div', {
    staticClass: "card-shadow mb-3"
  }, [_c('div', {
    staticClass: "content-space-between event-calendar"
  }, [_vm._m(0), _c('span', [_vm._v(_vm._s(_vm.formatDateTimesDay(_vm.eventDate)))])]), _c('div', {
    staticClass: "title-event content-space-between"
  }, [_c('img', {
    staticClass: "banner-small me-3",
    attrs: {
      "src": _vm.imgBanner
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "w-100"
  }, [_vm._v(_vm._s(_vm.eventName))])]), _c('div', {
    staticClass: "content-space-between"
  }, [_c('p', {
    staticClass: "m-0 text-black font-size-12"
  }, [_vm._v("Selesaikan Pemesanan Dalam")]), _c('p', {
    staticClass: "m-0"
  }, [_c('span', {
    staticClass: "badges-timer m-0"
  }, [_vm._v(_vm._s(_vm.hoursTimer.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })))]), _c('span', {
    staticClass: "font-size-12 font-weight-600 text-primary"
  }, [_vm._v(" : ")]), _c('span', {
    staticClass: "badges-timer m-0"
  }, [_vm._v(_vm._s(_vm.minutesTimer.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })))]), _vm._v(" "), _c('span', {
    staticClass: "font-size-12 font-weight-600 text-primary"
  }, [_vm._v(":")]), _vm._v(" "), _c('span', {
    staticClass: "badges-timer m-0"
  }, [_vm._v(_vm._s(_vm.secondsTimer.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })))])])])]), _c('div', {
    staticClass: "card-shadow-total mb-3"
  }, [_c('div', {
    staticClass: "container-ticket"
  }, [_c('img', {
    staticClass: "icon-12 me-2",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-ticket-grey.png")
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "font-weight-600"
  }, [_vm._v("Tiket Kamu")]), _c('div', {
    staticClass: "mt-2"
  }, _vm._l(_vm.groupedOrderSummary, function (item, key) {
    return _c('div', {
      key: key
    }, [_c('div', {
      staticClass: "sub-total-ticket"
    }, [_c('span', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.category_name))]), _c('span', [_vm._v(_vm._s(_vm.formatCurrency((item === null || item === void 0 ? void 0 : item.sum_price) / (item === null || item === void 0 ? void 0 : item.sum_quantity))))])]), _c('div', {
      staticClass: "total-ticket mb-1"
    }, [_c('span', {
      staticClass: "font-weight-700 font-size-12"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.sum_quantity) + "x " + _vm._s(item === null || item === void 0 ? void 0 : item.category_name))]), _c('span', {
      staticClass: "font-weight-700 font-size-12"
    }, [_vm._v(_vm._s(_vm.formatCurrency(item === null || item === void 0 ? void 0 : item.sum_price)))])])]);
  }), 0), !_vm.paymentMethod ? _c('div', {
    staticClass: "toast-fixed info mb-3 mt-3"
  }, [_vm._m(1)]) : _c('div', {
    staticClass: "total-ticket admin-fee mb-3 mt-3"
  }, [_c('span', {
    staticClass: "font-weight-400 font-size-12"
  }, [_vm._v("Biaya Layanan")]), _c('span', {
    staticClass: "font-weight-600 font-size-12"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.admin_fee)))])]), _c('div', {
    staticClass: "summary-qty mt-2"
  }, [_c('span', [_vm._v("Total Pembayaran")]), _c('span', {
    staticClass: "font-weight-600"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.totalAmount)))])])])]), _c('div', {
    staticClass: "font-size-12 font-weight-600 mb-2"
  }, [_vm._v("Pilih Metode Pembayaran")]), _vm.discounts.length > 0 ? _c('div', {
    staticClass: "card-disclaimer content-space-between mb-2"
  }, [_c('img', {
    staticClass: "me-1 icon-12",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-label.png")
    }
  }), _c('span', {
    staticClass: "font-size-10 font-weight-400 text-black w-100"
  }, [_vm._v("Dapatkan potongan 25% untuk pembayaran menggunakan kartu kredit Bank Mandiri bagi 200 tiket pertama")])]) : _vm._e(), _c('div', {
    staticClass: "container-cat mb-2"
  }, [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.credit",
      modifiers: {
        "credit": true
      }
    }],
    staticClass: "header-title",
    attrs: {
      "active": ""
    }
  }, [_vm._m(2)]), _c('b-collapse', {
    staticClass: "container-exp",
    attrs: {
      "id": "credit"
    }
  }, _vm._l(_vm.listPayment, function (item, key) {
    return _c('div', {
      key: key
    }, [(item === null || item === void 0 ? void 0 : item.type_channel) == 'credit-card' ? _c('div', {
      staticClass: "card-payment-method content-space-between mt-2"
    }, [_c('div', {
      staticClass: "bg-logo-radio pe-1 ps-1 me-2"
    }, [_c('img', {
      staticClass: "logo-radio",
      attrs: {
        "id": 'img_' + (item === null || item === void 0 ? void 0 : item.payment_id),
        "src": item === null || item === void 0 ? void 0 : item.logo
      }
    })]), _c('span', {
      staticClass: "font-size-12 font-weight-400 text-black payment-name w-100"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.name))]), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.paymentMethod,
        expression: "paymentMethod"
      }],
      staticClass: "form-check-input",
      attrs: {
        "type": "radio",
        "name": "payment",
        "id": item === null || item === void 0 ? void 0 : item.payment_id,
        "aria-label": "..."
      },
      domProps: {
        "value": item,
        "checked": _vm._q(_vm.paymentMethod, item)
      },
      on: {
        "change": function ($event) {
          _vm.paymentMethod = item;
        }
      }
    })]) : _vm._e()]);
  }), 0)], 1), _c('div', {
    staticClass: "container-cat mb-2"
  }, [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.va",
      modifiers: {
        "va": true
      }
    }],
    staticClass: "header-title"
  }, [_vm._m(3)]), _c('b-collapse', {
    staticClass: "container-exp",
    attrs: {
      "id": "va"
    }
  }, _vm._l(_vm.listPayment, function (item, key) {
    return _c('div', {
      key: key
    }, [(item === null || item === void 0 ? void 0 : item.type_channel) !== 'credit-card' ? _c('div', {
      staticClass: "card-payment-method content-space-between mt-2"
    }, [_c('div', {
      staticClass: "bg-logo-radio pe-1 ps-1 me-2"
    }, [_c('img', {
      staticClass: "logo-radio",
      attrs: {
        "id": 'img_' + (item === null || item === void 0 ? void 0 : item.payment_id),
        "src": item === null || item === void 0 ? void 0 : item.logo
      }
    })]), _c('span', {
      staticClass: "font-size-12 font-weight-400 text-black payment-name w-100"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.name))]), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.paymentMethod,
        expression: "paymentMethod"
      }],
      staticClass: "form-check-input",
      attrs: {
        "type": "radio",
        "name": "payment",
        "id": item === null || item === void 0 ? void 0 : item.payment_id,
        "aria-label": "..."
      },
      domProps: {
        "value": item,
        "checked": _vm._q(_vm.paymentMethod, item)
      },
      on: {
        "change": function ($event) {
          _vm.paymentMethod = item;
        }
      }
    })]) : _vm._e()]);
  }), 0)], 1)])]), _c('div', {
    staticClass: "footer-form"
  }, [_c('button', {
    staticClass: "btn btn-primary w-100",
    attrs: {
      "type": "button",
      "disabled": _vm.paymentMethod == null
    },
    on: {
      "click": function ($event) {
        return _vm.createPayment();
      }
    }
  }, [_vm._v(" Bayar Pesanan ")])]), _c('Loading', {
    attrs: {
      "isLoading": _vm.loading
    }
  }), _c('b-modal', {
    ref: "failed-modal",
    attrs: {
      "size": "sm",
      "id": "failed-modal",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "warning-icon mb-2",
    attrs: {
      "src": require("../assets/img/warning-icon.png")
    }
  }), _c('p', {
    staticClass: "font-size-20 font-weight-600 mb-2"
  }, [_vm._v(_vm._s((_vm$error = _vm.error) === null || _vm$error === void 0 ? void 0 : _vm$error.title))]), _c('p', {
    staticClass: "font-size-12 font-weight-400 mb-5"
  }, [_vm._v(_vm._s((_vm$error2 = _vm.error) === null || _vm$error2 === void 0 ? void 0 : _vm$error2.message))]), _c('button', {
    staticClass: "btn btn-primary btn-sm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('failed-modal');
      }
    }
  }, [_vm._v("Ok, Terima Kasih")])])]), _c('b-modal', {
    ref: "confirm-modal",
    attrs: {
      "size": "sm",
      "id": "confirm-modal",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('p', {
    staticClass: "font-size-12 font-weight-600 mb-2"
  }, [_vm._v("Apakah Anda yakin ingin meninggalkan halaman ini?")]), _c('p', {
    staticClass: "font-size-10 font-weight-400 mb-4"
  }, [_vm._v("Anda harus membatalkan pesanan terlebih dahulu untuk meninggalkan halaman ini")]), _c('div', {
    staticClass: "w-100 me-0 d-flex flex-row justify-content-end align-items-center"
  }, [_c('button', {
    staticClass: "btn button-default text-primary me-3 font-size-10 font-weight-600",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('confirm-modal');
      }
    }
  }, [_vm._v("Tetap Disini")]), _c('button', {
    staticClass: "btn button-rounded bg-danger font-size-10 font-weight-600 btn-xxsm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.BackToEventWithActiveRoom();
      }
    }
  }, [_vm._v("Cancel Order")])])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('img', {
    staticClass: "me-2",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-calendar-grey.png")
    }
  }), _c('span', [_vm._v("Tanggal Event")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "message-claimed"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/img/fi-rr-info.png")
    }
  }), _c('span', {
    staticClass: "ms-2"
  }, [_vm._v("Total Pembayaran tiket belum termasuk Biaya Layanan pada masing-masing metode pembayaran yang digunakan")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "sub-sub-title text-dark font-weight-600 title-page d-flex m-0"
  }, [_c('span', {
    staticClass: "w-100"
  }, [_vm._v("Kartu Kredit")]), _c('span', {
    staticClass: "icon-arrow when-closed"
  }, [_c('img', {
    staticClass: "icon-12",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-down.png")
    }
  })]), _c('span', {
    staticClass: "icon-arrow when-open"
  }, [_c('img', {
    staticClass: "icon-12",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-up.png")
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "sub-sub-title text-dark font-weight-600 title-page d-flex m-0"
  }, [_c('span', {
    staticClass: "w-100"
  }, [_vm._v("Virtual Account")]), _c('span', {
    staticClass: "icon-arrow when-closed"
  }, [_c('img', {
    staticClass: "icon-12",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-down.png")
    }
  })]), _c('span', {
    staticClass: "icon-arrow when-open"
  }, [_c('img', {
    staticClass: "icon-12",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-up.png")
    }
  })])]);
}]

export { render, staticRenderFns }