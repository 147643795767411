var render = function () {
  var _vm$detail, _vm$detail2, _vm$detail3, _vm$detail4, _vm$error, _vm$error2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-page"
  }, [_vm.isHeader ? _c('HeaderComp', {
    attrs: {
      "title": "",
      "url": "/e-ticket"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "card-page mt-5"
  }, [_c('div', {
    staticClass: "bg-white p-3"
  }, [_c('p', {
    staticClass: "font-size-14 font-weight-400 text-black text-center mb-3 ps-5 pe-5"
  }, [_vm._v("Upload Selfie untuk Check In via Face Recognition")]), _vm.photo == null ? _c('div', {
    staticClass: "foto-empty col-centered mb-3"
  }, [_c('div', {
    staticClass: "upload-btn-wrapper"
  }, [_c('button', {
    staticClass: "btn btn-primary button-upload"
  }, [_vm._v("Upload Photo")]), _c('input', {
    ref: "photos",
    attrs: {
      "type": "file",
      "name": "myfile",
      "accept": ".png, .jpg, .jpeg",
      "capture": "user"
    },
    on: {
      "change": _vm.uploadPhoto
    }
  })])]) : _c('div', {
    staticClass: "foto-filled col-centered mb-3",
    style: {
      'background-image': 'url(' + _vm.imageSelfie + ')'
    }
  }, [_c('div', {
    staticClass: "upload-btn-wrapper"
  }, [_c('button', {
    staticClass: "btn btn-delete text-danger button-upload",
    on: {
      "click": _vm.deletePhoto
    }
  }, [_vm._v("Delete Photo")])])]), _c('div', {
    staticClass: "center-content"
  }, [_c('div', {
    staticClass: "card-event w-100 mt-2"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('p', {
    staticClass: "m-0 cat-idt"
  }, [_vm._v("Nama Pengunjung")]), _c('p', {
    staticClass: "m-0 font-size-14 font-weight-600"
  }, [_vm._v(_vm._s((_vm$detail = _vm.detail) === null || _vm$detail === void 0 ? void 0 : _vm$detail.ticket_attendee_name))])]), _c('div', {
    staticClass: "mb-2"
  }, [_c('p', {
    staticClass: "m-0 cat-idt"
  }, [_vm._v("Tiket Kategori")]), _c('p', {
    staticClass: "m-0 font-size-14 font-weight-600"
  }, [_vm._v(_vm._s((_vm$detail2 = _vm.detail) === null || _vm$detail2 === void 0 ? void 0 : _vm$detail2.ticket_category_name))])]), _c('div', {
    staticClass: "mb-2"
  }, [_c('p', {
    staticClass: "m-0 cat-idt"
  }, [_vm._v("Event Schedule")]), _c('p', {
    staticClass: "m-0 font-size-14 font-weight-600"
  }, [_vm._v(_vm._s((_vm$detail3 = _vm.detail) === null || _vm$detail3 === void 0 ? void 0 : _vm$detail3.event_start_date))])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('p', {
    staticClass: "m-0 cat-idt"
  }, [_vm._v("Lokasi")]), _c('p', {
    staticClass: "m-0 font-size-12 font-weight-600"
  }, [_vm._v(_vm._s((_vm$detail4 = _vm.detail) === null || _vm$detail4 === void 0 ? void 0 : _vm$detail4.event_address))])])])])])]), _vm._m(0), _c('b-modal', {
    ref: "failed-modal",
    attrs: {
      "size": "sm",
      "id": "failed-modal",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "warning-icon mb-2",
    attrs: {
      "src": require("../assets/img/warning-icon.png")
    }
  }), _c('p', {
    staticClass: "font-size-20 font-weight-600 mb-2"
  }, [_vm._v(_vm._s((_vm$error = _vm.error) === null || _vm$error === void 0 ? void 0 : _vm$error.title))]), _c('p', {
    staticClass: "font-size-12 font-weight-400 mb-5"
  }, [_vm._v(_vm._s((_vm$error2 = _vm.error) === null || _vm$error2 === void 0 ? void 0 : _vm$error2.message))]), _c('button', {
    staticClass: "btn btn-primary btn-sm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('failed-modal');
      }
    }
  }, [_vm._v("Ok, Terima Kasih")])])]), _c('Loading', {
    attrs: {
      "isLoading": _vm.loading
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center p-3"
  }, [_c('img', {
    staticClass: "logo-metalgo-footer",
    attrs: {
      "src": require("../assets/img/logo-metalgo-ticket.png")
    }
  })]);
}]

export { render, staticRenderFns }