<template>
  <div class="v-upload-field" @dragover="onDragover" @drop="onDrop">
    <div class="upload-box" v-if="dragover" @dragleave="onDragleave">
      <div class="upload-preview">
        <span class="font-size-xsmall text-default font-bold text-center">{{
          $t("infaq.dropHere")
        }}</span>
      </div>
    </div>
    <div class="upload-box cursor-pointer" v-show="!dragover" @click="pickFile()">
      <div class="upload-preview" v-if="displayed">
        <div class="container-image" @click.stop="showModal('video-modal')">
          <img :src="displayed" :alt="label" />
          <button class="btn bg-secondary text-white font-size-small" @click.stop="removeFile()">
            {{ $t("infaq.remove") }}
          </button>
        </div>
      </div>
      <div class="upload-preview" v-if="(image || imageView) && isImage">
        <div class="container-image" @click.stop="showModal('video-modal')">
          <img :src="display" :alt="label" v-if="imageView == null" />
          <img :src="viewImage(imageView)" :alt="label" v-else />
          <button class="btn bg-secondary text-white font-size-small" @click.stop="removeFile()">
            {{ $t("infaq.remove") }}
          </button>
        </div>
      </div>
      <div class="upload-preview" v-if="(image || imageView) && isVideo">
        <div class="container-image">
          <video :src="viewImage(image, true)" :alt="label" v-if="imageView == null" />
          <video :src="viewImage(imageView, true)" :alt="label" v-else />
          <div class="overlay-player cursor-pointer" @click.stop="showModal('video-modal')">
            <div class="player-icon">
              <play-circle-icon class="text-default" size="3x"></play-circle-icon>
            </div>
          </div>
          <button class="btn bg-secondary text-white font-size-small" @click.stop="removeFile()">
            {{ $t("infaq.remove") }}
          </button>
        </div>
      </div>
      <div class="upload-preview files" v-if="!isImage && !isVideo && value">
        <div class="container-image">
          <div class="
                              font-size-xsmall
                              text-dark
                              font-bold
                              text-center
                              center-content
                            ">
            {{ filename }}
          </div>
          <button class="btn bg-secondary text-white font-size-small" @click.stop="removeFile()">
            {{ $t("infaq.remove") }}
          </button>
        </div>
      </div>
      <div class="upload-empty" v-if="!filename && !image && !displayed">
        <span class="font-size-xsmall text-default font-bold text-center">{{
          $t("infaq.filePlaceholder")
        }}</span>
      </div>
    </div>
    <input type="file" ref="input" class="file" @change="processFile" :disabled="disabled" />
    <b-modal ref="video-modal" hide-footer hide-header no-close-on-backdrop no-close-on-esc>
      <div>
        <div class="container-video">
          <video width="100%" controls v-if="isVideo">
            <source :src="image" type="video/mp4" v-if="imageView == null" />
            <source :src="viewImage(imageView, true)" type="video/mp4" />
            {{ $t("infaq.browserInfo") }}.
          </video>
          <div v-if="isImage">
            <img class="center-content view-image" :src="image" :alt="label" v-if="imageView == null" />
            <img class="center-content view-image" :src="viewImage(imageView)" :alt="label" v-else />
          </div>
          <button class="btn btn-secondary p-0" @click="hideModal('video-modal')">
            X
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { FileIcon, FileTextIcon, PlayCircleIcon } from "vue-feather-icons";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  name: "FilePicker",
  components: {
    FileIcon,
    FileTextIcon,
    PlayCircleIcon,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: "Upload",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: Number,
      required: false,
    },
    value: {
      type: File,
      required: false,
    },
    display: {
      type: String,
      required: false,
    },
    config: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      dragover: false,
      image: null,
      filename: null,
      isImage: true,
      isVideo: false,
      displayed: this.display,
      imageView: this.value,
      type: [],
    };
  },
  methods: {
    showModal(id) {
      this.$refs[id].show();
    },
    hideModal(id) {
      this.$refs[id].hide();
    },
    pickFile() {
      if (this.filename == null) {
        this.$refs.input.click();
      }
    },
    viewImage(val, isVideo) {
      if (val.type.split("/")[0] == "image") {
        this.isImage = true;
        this.isVideo = false;
      } else if (val.type.split("/")[0] == "video") {
        this.isImage = false;
        this.isVideo = true;
      } else {
        this.isImage = false;
        this.isVideo = false;
      }
      this.filename = val.name;
      if (isVideo == true) {
        const url = URL.createObjectURL(val);
        return url;
      }
      return URL.createObjectURL(val);
    },
    typeImage(files) {
      if (files) {
        let mimeType = files.type;
        if (mimeType.split("/")[0] === "image") {
          const previewImage = URL.createObjectURL(files);
          this.image = previewImage;
          this.imageView = files;
          this.filename = files.name;
          this.isImage = true;
          this.isVideo = false;
        } else if (mimeType.split("/")[0] === "video") {
          const previewImage = URL.createObjectURL(files);
          this.image = previewImage;
          this.imageView = files;
          this.filename = files.name;
          this.isImage = false;
          this.isVideo = true;
        } else {
          this.filename = files.name;
          this.isImage = false;
          this.isVideo = false;
        }
      }
    },
    validate(files) {
      if (files.size == 0) {
        Swal.fire({
          icon: "error",
          title: this.$t("alert.failed"),
          text: this.$t("lembaga.fileCorrupt"),
        });
        return false;
      }
      if (this.config.length > 0) {
        let mimeType = files.type.split("/")[1];
        let idx = this.config.findIndex((element) => element.type == mimeType);
        if (idx == -1) {
          const type = this.getFormatList(this.config);
          Swal.fire({
            icon: "error",
            title: this.$t("alert.failed"),
            text: this.$t("validation.fileFormat") + " [" + type.toString() + "]",
          });
          return false;
        } else {
          if (files.size / 1024 / 1024 > this.config[idx].size) {
            Swal.fire({
              icon: "error",
              title: this.$t("alert.failed"),
              text: this.$t("validation.maxFile") + " " + this.config[idx].size + " MB !",
            });
            return false;
          }
        }
      }
      return true;
    },
    getFormatList(format) {
      let type = [];
      format.forEach((element) => {
        type.push(element.type);
      });
      return type;
    },
    async processFile(e) {
      try {
        let files = this.$refs.input.files;
        let file = files?.item(0);
        if (files == null) {
          this.$refs.input.files = this.imageView;
          files = this.$refs.input.files;
          file = files?.item(0);
        }
        if (file) {
          if (this.validate(file)) {
            let like = this;
            this.compressImage(file).then(function (imgCompressed) {
              if (imgCompressed) {
                like.$emit("input", imgCompressed);
                like.$emit("removing", { status: false, id: null });
                like.typeImage(imgCompressed);
              }
            });

            // const form = new FormData();
            // if (this.type === "file") {
            //   form.append("file", file);
            //   const res = await UploadServices.uploadFile(form);
            //   if (this.withName) {
            //     this.$emit("input", {
            //       file: res.data.file,
            //       name: file.name,
            //     });
            //   } else {
            //     this.$emit("input", res.data.file);
            //   }
            // } else if (this.type === "image") {
            // form.append("image", file);
            // const res = await UploadServices.uploadImage(form);
            // if (this.withName) {
            //   this.$emit("input", {
            //     file: res.data.image,
            //     name: file.name,
            //   });
            // } else {
            //   this.$emit("input", res.data.image);
            //   // }
            // }
          } else {
            this.image = null;
            this.imageView = null;
            this.filename = null;
            this.$refs.input.value = null;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    compressImage(blobImg) {
      // console.log(blobImg.size);
      try {
        let file = blobImg;
        let quality = 100;
        var percentage = 0.5;
        return new Promise(function (rs) {
          const loadImage = (src) =>
            new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = () => resolve(img);
              img.onerror = reject;
              img.src = src;
            });
          var currentFile = file;
          const compressing = (bitmap) => {
            return new Promise((resolve) => {
              var canvas = document.createElement("canvas");
              var ctx = canvas.getContext("2d");
              canvas.height = canvas.width * (bitmap.height / bitmap.width);
              var oc = document.createElement("canvas"),
                octx = oc.getContext("2d");
              oc.width = bitmap.width * percentage;
              oc.height = bitmap.height * percentage;
              canvas.width = oc.width;
              canvas.height = oc.height;
              octx.drawImage(bitmap, 0, 0, oc.width, oc.height);
              octx.drawImage(oc, 0, 0, oc.width, oc.height);
              ctx.drawImage(
                oc,
                0,
                0,
                oc.width,
                oc.height,
                0,
                0,
                canvas.width,
                canvas.height
              );
              let dataUrl = canvas.toDataURL("image/jpeg", quality);
              var byteString = atob(dataUrl.split(",")[1]);
              // separate out the mime component
              var mimeString = dataUrl
                .split(",")[0]
                .split(":")[1]
                .split(";")[0];
              // write the bytes of the string to an ArrayBuffer
              var ab = new ArrayBuffer(byteString.length);
              var ia = new Uint8Array(ab);
              for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
              }
              let filess = new Blob([ab], { type: mimeString });
              const resultFile = new File([filess], "file_compress.jpg", {
                type: "image/jpeg",
                lastModified: Date.now(),
              });
              currentFile = resultFile;
              quality -= 2;
              // console.log(currentFile.size);
              // console.log(quality);
              if (currentFile.size >= 400000 && quality > 5) {
                loadImage(window.URL.createObjectURL(currentFile)).then(
                  (image) => {
                    compressing(image);
                  }
                );
              } else {
                resolve(currentFile);
                rs(currentFile);
              }
            });
          };
          loadImage(window.URL.createObjectURL(file)).then((image) => {
            compressing(image);
          });
        });
      } catch (ex) {
        console.log(ex);
        return null;
      }
    },
    onDragleave() {
      this.dragover = false;
    },
    onDragover(e) {
      if (this.filename == null) {
        e.preventDefault();
        this.dragover = true;
      }
    },
    async onDrop(e) {
      if (this.filename == null) {
        e.preventDefault();
        this.dragover = false;
        if (e.dataTransfer) {
          try {
            let files = e.dataTransfer.files;
            let file = files?.item(0);
            if (files == null) {
              this.$refs.input.files = this.imageView;
              files = this.$refs.input.files;
              file = files?.item(0);
            }
            if (file) {
              if (this.validate(file)) {
                this.$emit("input", file);
                this.$emit("removing", { status: false, id: null });
                this.typeImage(file);
                // const form = new FormData();
                // if (this.type === "file") {
                //   form.append("file", file);
                //   const res = await UploadServices.uploadFile(form);
                //   this.$emit("input", res.data.file);
                // } else if (this.type === "image") {
                //   form.append("image", file);
                //   const res = await UploadServices.uploadImage(form);
                //   this.$emit("input", res.data.image);
                // }
              } else {
                this.image = null;
                this.imageView = null;
                this.filename = null;
                this.$refs.input.value = null;
              }
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    removeFile() {
      this.$emit("input", null);
      this.$emit("removing", { status: true, id: this.id });
      this.typeImage(this.value);
      if (this.displayed) {
        this.displayed = null;
      }
    },
  },
  watch: {
    value: function (val) {
      if (val == null) {
        this.image = null;
        this.imageView = null;
        this.filename = null;
        this.$refs.input.value = null;
      } else {
        this.typeImage(val);
      }
    },
    display: function (val) {
      if (val == null) {
        this.image = null;
        this.imageView = null;
        this.filename = null;
        this.$refs.input.value = null;
      } else {
        this.typeImage(val);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-upload-field {
  margin: 16px 0;
  display: flex;
  align-items: center;
  position: relative;
}

// .cloak {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: #fff6;
//   padding: 16px;
//   font-size: 1.6rem;
//   backdrop-filter: blur(8px) saturate(112%);
// }
.upload-box {
  flex: 0 0 auto;
  width: 110px;
  height: 103px;
  background: rgba(247, 249, 251, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  // &:hover {
  //   background: #efefef88;
  // }
}

.upload-label {
  margin-left: 32px;
  flex: 1 1 0;
  width: 0;
}

.upload-preview {
  width: 83px;
  height: 83px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 2px dashed #e2e6ea;
  border-radius: 10px;

  &.files {
    border: 2px dashed #e2e6ea;
  }

  .container-image {
    position: relative;
    width: 83px;
    height: 83px;

    // width: 100%;
    img {
      width: 100%;
      border-radius: 10px;
      height: 100%;
      object-fit: cover;
    }

    video {
      width: 100%;
      border-radius: 10px;
      height: 100%;
      object-fit: cover;
    }

    div {
      width: 100%;
      border-radius: 10px;
      height: 100%;
    }
  }
}

.container-video {
  position: relative;
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
  }
}

.container-video button {
  position: absolute;
  top: -9%;
  left: 107%;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  transform: translate(-107%, -9%);
  -ms-transform: translate(-107%, -9%);
}

.view-image {
  // max-height: 100px;
  max-width: 300px;
}

.container-image button {
  position: absolute;
  top: 98%;
  left: 50%;
  transform: translate(-50%, -98%);
  -ms-transform: translate(-50%, -98%);
  // color: white;
  // font-size: 16px;
  backdrop-filter: blur(10px);
  // background-color: transparent;
  padding: 3px 5px;
  border-radius: 5px;
  text-align: center;
}

.overlay-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  backdrop-filter: blur(1px);
}

.overlay-player .player-icon {
  position: absolute;
  top: 70%;
  left: 85%;
  transform: translate(-60%, -60%);
  -ms-transform: translate(-60%, -60%);
}

.label-title {
  font-size: 1.6rem;
  // font-weight: var(--font-bold);
  margin: 0;
}

.label-subtitle {
  font-size: 1.4rem;
  // font-weight: var(--font-normal);
  margin: 8px 0;

  &.ellipsis {
    display: block;
    // width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.upload-empty {
  width: 83px;
  height: 83px;
  border: 2px dashed #e2e6ea;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file {
  display: none;
}

.ext {
  margin-top: 4px;
  text-align: center;
  // font-weight: var(--font-bold);
}
</style>
