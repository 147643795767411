<template>
    <div class="container-page">
        <!-- <HeaderComp title="" url="#" noback isBackToHome /> -->
        <div class="card-page">
            <div class="img-banner-bg h-100">
                <div class="center-content success-message h-100">
                    <img src="../assets/img/icon-busy.png" class="mb-2">
                    <p class="font-size-24 font-weight-500 text-dark text-center mb-2">Aktivitas pada Sistem Tinggi</p>
                    <p class="font-size-14 font-weight-400 text-desc-busy text-center me-3 ms-3">Sistem kami saat ini
                        sedang mengalami lonjakan aktivitas yang luar biasa. Coba lagi beberapa saat.</p>
                </div>
                <div class="footer-form">
                    <button class="btn btn-white text-primary w-100" type="button" @click="retryHit">
                        <span>Muat Ulang</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from "../components/Loading.vue";
import axios from "axios";
import HeaderComp from "../components/HeaderComp.vue";
export default {
    components: {
        Loading,
        HeaderComp,
    },
    data() {
        return {
            showDetail: false,
            isDisabledRef: false,
            isLoading: false
        };
    },
    methods: {
        retryHit() {
            localStorage.setItem('lastPathData', 'busy-server');
            let last = localStorage.getItem('currentPath');
            let cc = localStorage.getItem('statusCC');
            let base_url_event = localStorage.getItem('base_url_event');
                window.location.href = base_url_event;
                // if (last == '/event') {
                //     window.location.href = base_url_event;
                // } else if (last == '/check-status') {
                //     window.location.href = cc;
                // } else {
                //     this.$router.push(last);
                // }
          

        }
    },
    computed: {

    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.text-desc-busy {
    color: #647179;
}

.disabled-qty {
    color: #647179 !important;
    cursor: not-allowed;
}

.rounded-footer {
    border-radius: 16px 16px 0px 0px !important;
}

.message-claimed {
    display: flex;
    justify-content: space-between;
    font-size: 0.625rem;
}

.message-claimed img {
    width: 3.59vw;
    height: 3.59vw;
}

.message-claimed span {
    font-size: 0.625rem;
}

.total-ticket {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: #000000;
    font-weight: 400;
}

.container-ticket i {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    display: inline-block;
}

.container-ticket {
    padding: 2vw;
    font-size: 0.75rem;
}

.card-summary {
    padding: 2vw;
    border: 1px solid #E7ECF4;
    border-radius: 8px;
}

.summary-detail {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
}

.summary-detail i {
    font-size: 1rem;
    color: #8E8E93;
}

.summary-qty {
    display: flex;
    justify-content: space-between;
    font-size: 0.85rem;
}

.link-text {
    text-decoration: underline;
}

.disclaimer-text {
    padding: 2vw;
    font-size: 0.625rem;
}

.text-qty {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: -0.5px;
    text-align: center;
    display: inline-flex;
}

.icon-arrow {
    font-size: 0.75rem;
    line-height: 12px;
    align-self: center;
}

.icon-qty {
    margin-top: 3px;
    font-size: 0.75rem;
    line-height: 13px;
    letter-spacing: -0.5px;
    display: inline-flex;
}

.ticket-qty {
    font-size: 0.75rem;
    align-self: center;
}

.ticket-detail-name {
    font-size: 0.75rem;
}

.ticket-detail-number {
    font-size: 0.625rem;
}

.cat-ticket {
    border: 1px solid #F2F5F7;
    padding: 2vw;
    display: flex;
    justify-content: space-between;
}

.sub-sub-title {
    border-bottom: none !important;
    font-size: 0.625rem;
    /* font-weight: bold;
    margin: 0 0 30px; */
    cursor: pointer;
}

.sub-sub-title span {
    vertical-align: middle;
}

.header-title {
    padding: 2vw 2vw;
    background: #F2F5F7;
    border-radius: 4px;
}

.desc-card {
    background: #F2F5F7;
    font-size: 0.625rem;
    border-radius: 8px;
    padding: 8px;
}

.desc-title {
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 16px;
}

.address-redirect {
    font-size: 16px;
}

.address-detail {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 75vw;
    line-height: 14.52px;
    font-size: 0.75rem;
    font-weight: 600;
    white-space: nowrap;
}

.address-city {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 75vw;
    color: #8E8E93;
    line-height: 14.52px;
    font-size: 0.75rem;
    font-weight: 400;
    white-space: nowrap;
}

.maps-img img {
    width: 32px;
    height: 32px;
    border-radius: 8px;
}

.title-event {
    font-size: 1rem;
    font-weight: 600;
}

.event-calendar {
    font-size: 0.75rem;
}

.event-calendar span {
    font-weight: 400;
}

.img-banner-bg {
    background-image: url("../assets/img/bg-busy.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.success-message {
    padding-left: 15vw;
    padding-right: 15vw;
}

.success-message img {
    width: 19vw;
    height: 19vw;
}

.container-content {
    background-color: white;
    padding: 4vw;
    margin-top: 49vw;
    border-radius: 16px 16px 0px 0px;
}

.card-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.footer-form {
    width: 100%;
    padding: 4vw 4vw;
}
</style>