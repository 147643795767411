var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-page"
  }, [_c('HeaderComp', {
    attrs: {
      "title": "",
      "url": "/detail-order"
    }
  }), _c('div', {
    staticClass: "card-page"
  }, [_c('div', {
    staticClass: "form-order"
  }, [_c('div', {
    staticClass: "title-page mb-3"
  }, [_vm._v("Data Pemesan")]), _vm._m(0), _c('div', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "form-floating"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$v.formOrderer.name.$model,
      expression: "$v.formOrderer.name.$model"
    }],
    class: ['form-control', {
      'border-danger': _vm.$v.formOrderer.name.$error
    }],
    attrs: {
      "type": "text",
      "id": "name",
      "placeholder": "Masukkan Nama Lengkap Anda"
    },
    domProps: {
      "value": _vm.$v.formOrderer.name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.formOrderer.name, "$model", $event.target.value);
      }
    }
  }), _vm._m(1)]), _vm.$v.formOrderer.name.$error ? _c('div', {
    staticClass: "text-danger font-size-10 mt-1"
  }, [!_vm.$v.formOrderer.name.required ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Nama lengkap tidak boleh kosong")]) : _vm._e(), !_vm.$v.formOrderer.name.minLength || !_vm.$v.formOrderer.name.maxLength ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Nama lengkap terdiri dari 3 - 40 karakter")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "form-floating"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$v.formOrderer.phonenumber.$model,
      expression: "$v.formOrderer.phonenumber.$model"
    }],
    class: ['form-control', {
      'border-danger': _vm.$v.formOrderer.phonenumber.$error
    }],
    attrs: {
      "type": "text",
      "id": "phone",
      "placeholder": "Masukkan Nomor Handphone Anda"
    },
    domProps: {
      "value": _vm.$v.formOrderer.phonenumber.$model
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.formOrderer.phonenumber, "$model", $event.target.value);
      }, function ($event) {
        _vm.formOrderer.phonenumber = _vm.formOrderer.phonenumber.replace(/[^0-9]/g, '');
      }]
    }
  }), _vm._m(2)]), _vm.$v.formOrderer.phonenumber.$error ? _c('div', {
    staticClass: "text-danger font-size-10 mt-1"
  }, [!_vm.$v.formOrderer.phonenumber.required ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Nomor handphone tidak boleh kosong")]) : _vm._e(), !_vm.$v.formOrderer.phonenumber.minLength || !_vm.$v.formOrderer.phonenumber.maxLength ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Nomor Handphone terdiri dari 10 -15 digit")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "form-floating"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$v.formOrderer.email.$model,
      expression: "$v.formOrderer.email.$model"
    }],
    class: ['form-control', {
      'border-danger': _vm.$v.formOrderer.email.$error
    }],
    attrs: {
      "type": "email",
      "id": "email",
      "placeholder": "Masukkan Alamat Email Anda"
    },
    domProps: {
      "value": _vm.$v.formOrderer.email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.formOrderer.email, "$model", $event.target.value);
      }
    }
  }), _vm._m(3)]), _vm.$v.formOrderer.email.$error ? _c('div', {
    staticClass: "text-danger font-size-10 mt-1"
  }, [!_vm.$v.formOrderer.email.required ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Alamat email tidak boleh kosong")]) : _vm._e(), !_vm.$v.formOrderer.email.email ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Format alamat email tidak valid")]) : _vm._e()]) : _vm._e()])])]), _c('div', {
    staticClass: "footer-form"
  }, [_c('button', {
    staticClass: "btn btn-primary w-100",
    attrs: {
      "type": "button",
      "disabled": _vm.$v.formOrderer.$invalid
    },
    on: {
      "click": function ($event) {
        return _vm.saveOrderer();
      }
    }
  }, [_vm._v(" Simpan Data ")])]), _c('Loading', {
    attrs: {
      "isLoading": _vm.loading
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "toast-fixed info mb-3"
  }, [_c('div', {
    staticClass: "message-claimed"
  }, [_c('img', {
    staticClass: "me-1",
    attrs: {
      "src": require("../assets/img/fi-rr-info.png")
    }
  }), _c('div', {
    staticClass: "w-100"
  }, [_c('p', {
    staticClass: "m-0 font-size-12 font-weight-600 text-black"
  }, [_vm._v("Pastikan data yang diisi benar!")]), _c('p', {
    staticClass: "m-0 font-size-10 font-weight-400 text-black"
  }, [_vm._v("Kami akan mengirimkan invoice ke alamat yang benar. ")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    attrs: {
      "for": "name"
    }
  }, [_vm._v("Nama Lengkap "), _c('span', {
    staticClass: "text-danger required-label"
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    attrs: {
      "for": "phone"
    }
  }, [_vm._v("Nomor Handphone "), _c('span', {
    staticClass: "text-danger required-label"
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Alamat Email "), _c('span', {
    staticClass: "text-danger required-label"
  }, [_vm._v("*")])]);
}]

export { render, staticRenderFns }