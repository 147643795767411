import Vue from "vue";
// import VueCookies from 'vue-cookies'
// import axios from 'axios';
import Vuex from 'vuex'
// import router from '../router'

Vue.use(Vuex);
// Vue.use(VueCookies);

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token'),
        user: JSON.parse(localStorage.getItem('user')),
    },
    getters: {
        token: state => {
            return state.token
        },
        user: state => {
            return state.user
        }
    }
});