var render = function () {
  var _vm$dataEvent, _vm$dataEvent2, _vm$dataEvent3, _vm$error, _vm$error2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-page"
  }, [_c('HeaderComp', {
    attrs: {
      "title": "",
      "url": "#",
      "noback": "",
      "isBackToList": ""
    }
  }), this.$route.params.name && this.$route.query.platform_key ? _c('div', {
    staticClass: "card-page"
  }, [_c('div', {
    staticClass: "img-banner-bg",
    style: {
      'background-image': 'url(' + _vm.imageBanner + ')'
    },
    attrs: {
      "id": "bg-event"
    }
  }, [_c('div', {
    staticClass: "w-100 me-0 d-flex flex-row justify-content-end align-items-center floating-button-detail",
    attrs: {
      "id": "floating-button-event"
    }
  }, [_c('button', {
    staticClass: "btn btn-primary rounded-button me-2 invisible",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/check-ticket');
      }
    }
  }, [_c('img', {
    staticClass: "ticket-icon",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-ticket.png")
    }
  })])]), _c('div', {
    staticClass: "container-content"
  }, [_c('div', {
    staticClass: "event-calendar"
  }, [_c('img', {
    staticClass: "me-1",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-calendar.png")
    }
  }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.formatDateTimesDay((_vm$dataEvent = _vm.dataEvent) === null || _vm$dataEvent === void 0 ? void 0 : _vm$dataEvent.start_date)))])]), _c('p', {
    staticClass: "text-dark title-event mb-2"
  }, [_vm._v(_vm._s((_vm$dataEvent2 = _vm.dataEvent) === null || _vm$dataEvent2 === void 0 ? void 0 : _vm$dataEvent2.name))]), _c('div', {
    staticClass: "d-flex mb-3"
  }, [_vm._m(0), _c('div', {
    staticClass: "address-event me-2"
  }, [_c('div', {
    staticClass: "address-detail"
  }, [_vm._v(_vm._s((_vm$dataEvent3 = _vm.dataEvent) === null || _vm$dataEvent3 === void 0 ? void 0 : _vm$dataEvent3.address))]), _c('div', {
    staticClass: "address-city"
  }, [_vm._v(_vm._s(_vm.cityName))])]), _c('div', {
    staticClass: "address-redirect",
    on: {
      "click": function ($event) {
        var _vm$dataEvent4, _vm$dataEvent5;
        return _vm.gotoMaps((_vm$dataEvent4 = _vm.dataEvent) === null || _vm$dataEvent4 === void 0 ? void 0 : _vm$dataEvent4.latitude, (_vm$dataEvent5 = _vm.dataEvent) === null || _vm$dataEvent5 === void 0 ? void 0 : _vm$dataEvent5.longitude);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-right-black.png")
    }
  })])]), _c('div', {
    staticClass: "desc-event mb-2"
  }, [_c('p', {
    staticClass: "desc-title mb-1"
  }, [_vm._v("Deskripsi")]), _c('div', {
    staticClass: "desc-card",
    domProps: {
      "innerHTML": _vm._s(_vm.markedDesc)
    }
  })]), _c('div', {
    staticClass: "container-cat mb-2"
  }, [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.ticket",
      modifiers: {
        "ticket": true
      }
    }],
    staticClass: "header-title",
    attrs: {
      "visible": ""
    }
  }, [_vm._m(1)]), _c('b-collapse', {
    staticClass: "container-exp",
    attrs: {
      "visible": "",
      "id": "ticket"
    }
  }, _vm._l(_vm.ticketData, function (item, key) {
    var _vm$ticketSummary, _vm$ticketSummary2, _vm$ticketSummary3;
    return _c('div', {
      key: key,
      staticClass: "cat-ticket mt-2"
    }, [_c('div', {
      staticClass: "ticket-detail"
    }, [_c('p', {
      staticClass: "font-weight-600 ticket-detail-name mb-0"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.category))]), new Date(new Date(item.sale_end_date).getTime()) < Date.now() ? _c('p', {
      staticClass: "font-weight-400 ticket-detail-number mb-0 text-danger"
    }, [_vm._v(" Tiket Tidak Tersedia ")]) : item !== null && item !== void 0 && item.qtySold ? _c('p', {
      staticClass: "font-weight-400 ticket-detail-number mb-0"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.markData(item === null || item === void 0 ? void 0 : item.description))
      }
    })]) : _c('p', {
      staticClass: "font-weight-400 ticket-detail-number mb-0 text-danger"
    }, [_vm._v("Tiket Kategori ini sudah habis ")]), _c('p', {
      staticClass: "font-weight-600 ticket-detail-name mb-0"
    }, [_vm._v(_vm._s(_vm.formatCurrency(item === null || item === void 0 ? void 0 : item.price)))])]), _c('div', {
      staticClass: "ticket-qty"
    }, [_c('button', {
      class: ['text-primary button-default icon-qty me-2', {
        'disabled-qty': (item === null || item === void 0 ? void 0 : item.qty) == 0 || {
          'disabled-qty': (item === null || item === void 0 ? void 0 : item.qty) == 0 || new Date(new Date(item.sale_end_date).getTime()) < Date.now()
        }
      }],
      attrs: {
        "type": "button",
        "disabled": (item === null || item === void 0 ? void 0 : item.qty) == 0
      },
      on: {
        "click": function ($event) {
          return _vm.diffTicket(key);
        }
      }
    }, [_vm._v("-")]), _c('span', {
      class: ['text-dark text-qty me-2', {
        'disabled-qty': (item === null || item === void 0 ? void 0 : item.qty) >= (item === null || item === void 0 ? void 0 : item.qtySold) || ((_vm$ticketSummary = _vm.ticketSummary) === null || _vm$ticketSummary === void 0 ? void 0 : _vm$ticketSummary.qtyTotal) >= _vm.orderMaxTicket || new Date(new Date(item.sale_end_date).getTime()) < Date.now()
      }]
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.qty))]), _c('button', {
      class: ['text-primary button-default icon-qty-plus me-2', {
        'disabled-qty': (item === null || item === void 0 ? void 0 : item.qty) >= _vm.orderMaxTicket || (item === null || item === void 0 ? void 0 : item.qty) >= (item === null || item === void 0 ? void 0 : item.qtySold) || ((_vm$ticketSummary2 = _vm.ticketSummary) === null || _vm$ticketSummary2 === void 0 ? void 0 : _vm$ticketSummary2.qtyTotal) >= _vm.orderMaxTicket || new Date(new Date(item.sale_end_date).getTime()) < Date.now()
      }],
      attrs: {
        "type": "button",
        "disabled": (item === null || item === void 0 ? void 0 : item.qty) >= _vm.orderMaxTicket || (item === null || item === void 0 ? void 0 : item.qty) >= (item === null || item === void 0 ? void 0 : item.qtySold) || ((_vm$ticketSummary3 = _vm.ticketSummary) === null || _vm$ticketSummary3 === void 0 ? void 0 : _vm$ticketSummary3.qtyTotal) >= _vm.orderMaxTicket || new Date(new Date(item.sale_end_date).getTime()) < Date.now()
      },
      on: {
        "click": function ($event) {
          return _vm.addTicket(key);
        }
      }
    }, [_c('b-icon-plus')], 1)])]);
  }), 0)], 1), _c('div', {
    staticClass: "container-cat mb-2"
  }, [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.rules",
      modifiers: {
        "rules": true
      }
    }],
    staticClass: "header-title"
  }, [_vm._m(2)]), _c('b-collapse', {
    staticClass: "container-exp",
    attrs: {
      "id": "rules"
    }
  }, [_c('div', {
    staticClass: "m-0 p-2 font-size-10",
    domProps: {
      "innerHTML": _vm._s(_vm.terms)
    }
  })])], 1)])])]) : _vm._e(), this.$route.params.name && this.$route.query.platform_key ? _c('div', {
    class: ['footer-form', {
      'rounded-footer': _vm.showDetail
    }]
  }, [_vm.showDetail == true ? _c('div', {
    staticClass: "container-summary"
  }, [_c('div', {
    staticClass: "summary-detail mb-2"
  }, [_c('div', {
    staticClass: "summary-title font-weight-600"
  }, [_vm._v("Rincian Pembayaran")]), _c('b-icon-x-lg', {
    staticClass: "summary-detail-close cursor-pointer",
    on: {
      "click": function ($event) {
        _vm.showDetail = false;
      }
    }
  })], 1), _c('div', {
    staticClass: "card-summary"
  }, [_c('div', {
    staticClass: "container-ticket mb-2"
  }, [_c('img', {
    staticClass: "ticket-grey-icon me-1",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-ticket-grey.png")
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "font-weight-600"
  }, [_vm._v("Tiket Kamu")]), _c('div', {
    staticClass: "mt-2"
  }, _vm._l(_vm.ticketData, function (item, key) {
    return _c('div', {
      key: key,
      staticClass: "total-ticket"
    }, [item.qty > 0 ? _c('span', [_vm._v(_vm._s(item.qty) + "x " + _vm._s(item.category))]) : _vm._e(), item.qty > 0 ? _c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.priceQty)))]) : _vm._e()]);
  }), 0), _vm._m(3)])])]) : _vm._e(), this.qtyTotalValidation > this.orderMaxTicket ? _c('div', {
    staticClass: "toast-fixed danger font-size-10 mt-2 p-2 text-danger"
  }, [_vm._v("Anda hanya bisa memesan maksimal " + _vm._s(_vm.orderMaxTicket) + " tiket dalam satu kali transaksi ")]) : _vm._e(), _c('div', {
    staticClass: "summary-qty mb-3 mt-2"
  }, [_c('span', [_vm._v("Total Pembayaran")]), _c('span', {
    class: ['font-weight-600', {
      'cursor-pointer': _vm.showDetail == false
    }],
    on: {
      "click": function ($event) {
        _vm.showDetail = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.ticketSummary.priceTotal)) + " "), _vm.showDetail == false ? _c('img', {
    staticClass: "icon-arrow-price",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-down.png")
    }
  }) : _c('img', {
    staticClass: "icon-arrow-price",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-up.png")
    }
  })])]), _c('button', {
    staticClass: "btn btn-primary w-100",
    attrs: {
      "type": "button",
      "disabled": _vm.qtyTotalValidation > _vm.orderMaxTicket || _vm.qtyTotalValidation == 0
    },
    on: {
      "click": function ($event) {
        return _vm.orderTicket();
      }
    }
  }, [_c('span', [_vm._v("Pesan Tiket")])])]) : _vm._e(), _c('b-modal', {
    ref: "failed-modal",
    attrs: {
      "size": "sm",
      "id": "failed-modal",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "warning-icon mb-2",
    attrs: {
      "src": require("../assets/img/warning-icon.png")
    }
  }), _c('p', {
    staticClass: "font-size-20 font-weight-600 mb-2"
  }, [_vm._v(_vm._s((_vm$error = _vm.error) === null || _vm$error === void 0 ? void 0 : _vm$error.title))]), _c('p', {
    staticClass: "font-size-12 font-weight-400 mb-5"
  }, [_vm._v(_vm._s((_vm$error2 = _vm.error) === null || _vm$error2 === void 0 ? void 0 : _vm$error2.message))]), _c('button', {
    staticClass: "btn btn-primary btn-sm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('failed-modal');
      }
    }
  }, [_vm._v("Ok, Terima Kasih")])])]), _c('Loading', {
    attrs: {
      "isLoading": _vm.loading
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "maps-img me-2"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/img/maps-icon.png")
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "sub-sub-title text-dark font-weight-600 title-page d-flex m-0"
  }, [_c('span', {
    staticClass: "w-100"
  }, [_vm._v("Tiket Tersedia")]), _c('img', {
    staticClass: "when-closed icon-arrow",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-down.png")
    }
  }), _c('img', {
    staticClass: "when-open icon-arrow",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-up.png")
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "sub-sub-title text-dark font-weight-600 title-page d-flex m-0"
  }, [_c('span', {
    staticClass: "w-100"
  }, [_vm._v("Syarat & Ketentuan")]), _c('img', {
    staticClass: "when-closed icon-arrow",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-down.png")
    }
  }), _c('img', {
    staticClass: "when-open icon-arrow",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-up.png")
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "toast-fixed info mt-3"
  }, [_c('div', {
    staticClass: "message-claimed"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/img/fi-rr-info.png")
    }
  }), _c('span', {
    staticClass: "ms-2"
  }, [_vm._v("Total Pembayaran tiket belum termasuk Biaya Layanan pada masing-masing metode pembayaran yang digunakan")])])]);
}]

export { render, staticRenderFns }