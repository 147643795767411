var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vld-parent"
  }, [_c('loading', {
    attrs: {
      "active": _vm.isLoading,
      "is-full-page": _vm.isFullpage,
      "loader": _vm.loader,
      "color": "#1C6DB8"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }