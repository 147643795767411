<template>
  <div class="container-page">
    <HeaderComp title="" url="#" noback isBackToList />
    <div class="card-page" v-if="this.$route.params.name && this.$route.query.platform_key">
      <div class="img-banner-bg" id="bg-event" :style="{ 'background-image': 'url(' + imageBanner + ')' }">
        <div class="w-100 me-0 d-flex flex-row justify-content-end align-items-center floating-button-detail"
          id="floating-button-event">
          <button class="btn btn-primary rounded-button me-2 invisible" @click="$router.push('/check-ticket')"><img
              src="../assets/img/icon/fi-rr-ticket.png" class="ticket-icon" /></button>
        </div>
        <div class="container-content">
          <div class="event-calendar">
            <img src="../assets/img/icon/fi-rr-calendar.png" class="me-1" /> <span>{{
              formatDateTimesDay(dataEvent?.start_date) }}</span>
          </div>
          <p class="text-dark title-event mb-2">{{ dataEvent?.name }}</p>
          <div class="d-flex mb-3">
            <div class="maps-img me-2">
              <img src="../assets/img/maps-icon.png">
            </div>
            <div class="address-event me-2">
              <div class="address-detail">{{ dataEvent?.address }}</div>
              <div class="address-city">{{ cityName }}</div>
            </div>
            <div class="address-redirect" @click="gotoMaps(dataEvent?.latitude, dataEvent?.longitude)">
              <img src="../assets/img/icon/fi-rr-angle-small-right-black.png" />
            </div>
          </div>
          <div class="desc-event mb-2">
            <p class="desc-title mb-1">Deskripsi</p>
            <div class="desc-card" v-html="markedDesc">
            </div>
          </div>
          <div class="container-cat mb-2">
            <div class="header-title" v-b-toggle.ticket visible>
              <p class="sub-sub-title text-dark font-weight-600 title-page d-flex m-0">
                <span class="w-100">Tiket Tersedia</span>
                <img src="../assets/img/icon/fi-rr-angle-small-down.png" class="when-closed icon-arrow" />
                <img src="../assets/img/icon/fi-rr-angle-small-up.png" class="when-open icon-arrow" />
              </p>
            </div>
            <b-collapse visible class="container-exp" id="ticket">
              <div class="cat-ticket mt-2" v-for="(item, key) in ticketData" :key="key">
                <div class="ticket-detail">
                  <p class="font-weight-600 ticket-detail-name mb-0">{{ item?.category }}</p>               
                  <p class="font-weight-400 ticket-detail-number mb-0 text-danger"
                    v-if="new Date(new Date(item.sale_end_date).getTime()) < Date.now()">
                    Tiket Tidak Tersedia
                  </p>
                  <p class="font-weight-400 ticket-detail-number mb-0" v-else-if="item?.qtySold"><span
                      v-html="markData(item?.description)"></span></p>

                  <p class="font-weight-400 ticket-detail-number mb-0 text-danger" v-else>Tiket Kategori ini sudah habis
                  </p>
                  <p class="font-weight-600 ticket-detail-name mb-0">{{ formatCurrency(item?.price) }}</p>
                </div>
                <div class="ticket-qty">
                  <button type="button"
                    :class="['text-primary button-default icon-qty me-2', { 'disabled-qty': item?.qty == 0 || { 'disabled-qty': item?.qty == 0 || new Date(new Date(item.sale_end_date).getTime()) < Date.now() } }]"
                    @click="diffTicket(key)" :disabled="item?.qty == 0">-</button>
                  <span
                    :class="['text-dark text-qty me-2', { 'disabled-qty': item?.qty >= item?.qtySold || ticketSummary?.qtyTotal >= orderMaxTicket || new Date(new Date(item.sale_end_date).getTime()) < Date.now() }]">{{
                      item?.qty }}</span>
                  <button type="button"
                    :class="['text-primary button-default icon-qty-plus me-2', { 'disabled-qty': item?.qty >= orderMaxTicket || item?.qty >= item?.qtySold || ticketSummary?.qtyTotal >= orderMaxTicket || new Date(new Date(item.sale_end_date).getTime()) < Date.now() }]"
                    @click="addTicket(key)"
                    :disabled="item?.qty >= orderMaxTicket || item?.qty >= item?.qtySold || ticketSummary?.qtyTotal >= orderMaxTicket || new Date(new Date(item.sale_end_date).getTime()) < Date.now()"><b-icon-plus></b-icon-plus></button>
                </div>
              </div>
              <!-- <div class="cat-ticket mt-2">
                <div class="ticket-detail">
                  <p class="font-weight-600 ticket-detail-name mb-0">Silver</p>
                  <p class="font-weight-400 ticket-detail-number mb-0">All Seating, No Seating Number</p>
                </div>
                <div class="ticket-qty">
                  <button type="button"
                    :class="['text-primary button-default icon-qty me-2', { 'disabled-qty': qtySilver == 0 }]"
                    @click="diffTicket('silver')" :disabled="qtySilver == 0"><i
                      class="fi fi-rr-minus-small"></i></button>
                  <span class="text-dark text-qty me-2">{{ qtySilver }}</span>
                  <button type="button"
                    :class="['text-primary button-default icon-qty me-2', { 'disabled-qty': qtySilver >= 5 || qtyTotal >= 5 }]"
                    @click="addTicket('silver')" :disabled="qtySilver >= 5 || qtyTotal >= 5"><i
                      class="fi fi-rr-plus-small"></i></button>
                </div>
              </div> -->
            </b-collapse>
          </div>
          <div class="container-cat mb-2">
            <div class="header-title" v-b-toggle.rules>
              <p class="sub-sub-title text-dark font-weight-600 title-page d-flex m-0">
                <span class="w-100">Syarat & Ketentuan</span>
                <img src="../assets/img/icon/fi-rr-angle-small-down.png" class="when-closed icon-arrow" />
                <img src="../assets/img/icon/fi-rr-angle-small-up.png" class="when-open icon-arrow" />
              </p>
            </div>
            <b-collapse class="container-exp" id="rules">
              <div class="m-0 p-2 font-size-10" v-html="terms"></div>
            </b-collapse>
          </div>
          <!-- <div class="container-cat mb-2">
            <div class="header-title" v-b-toggle.disclaimer>
              <p class="sub-sub-title text-dark font-weight-600 title-page d-flex m-0">
                <span class="w-100">Disclaimer</span>
                <img src="../assets/img/icon/fi-rr-angle-small-down.png" class="icon-arrow" />
              </p>
            </div>
            <b-collapse class="container-exp" id="disclaimer">
              <div class="disclaimer-text">Produk atau jasa ini disediakan dan menjadi tanggung jawab MetalGO Ticket.
                Untuk layanan Contact Center, hubungi 081217333188. Cek Syarat & Ketentuan lengkap <span
                  class="link-text text-info cursor-pointer"><a class="text-info" href="//bmr.id/sukhatncxahmaddhani">di
                    sini</a></span></div>
            </b-collapse>
          </div> -->
        </div>
      </div>
    </div>
    <div :class="['footer-form', { 'rounded-footer': showDetail }]"
      v-if="this.$route.params.name && this.$route.query.platform_key">
      <div class="container-summary" v-if="showDetail == true">
        <div class="summary-detail mb-2">
          <div class="summary-title font-weight-600">Rincian Pembayaran</div>
          <b-icon-x-lg class="summary-detail-close cursor-pointer" @click="showDetail = false"></b-icon-x-lg>
        </div>
        <div class="card-summary">
          <div class="container-ticket mb-2">
            <img src="../assets/img/icon/fi-rr-ticket-grey.png" class="ticket-grey-icon me-1"> <span
              class="font-weight-600">Tiket Kamu</span>
            <div class="mt-2">
              <div class="total-ticket" v-for="(item, key) in ticketData" :key="key">
                <span v-if="item.qty > 0">{{ item.qty }}x {{ item.category }}</span>
                <span v-if="item.qty > 0">{{ formatCurrency(item.priceQty) }}</span>
              </div>
            </div>
            <div class="toast-fixed info mt-3">
              <div class="message-claimed">
                <img src="../assets/img/fi-rr-info.png">
                <span class="ms-2">Total Pembayaran tiket belum termasuk Biaya Layanan pada masing-masing metode
                  pembayaran yang digunakan</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="toast-fixed danger font-size-10 mt-2 p-2 text-danger"
        v-if="this.qtyTotalValidation > this.orderMaxTicket">Anda hanya bisa memesan
        maksimal {{ orderMaxTicket }} tiket dalam satu kali transaksi
      </div>
      <div class="summary-qty mb-3 mt-2">
        <span>Total Pembayaran</span>
        <span :class="['font-weight-600', { 'cursor-pointer': showDetail == false }]" @click="showDetail = true"> {{
          formatCurrency(ticketSummary.priceTotal) }} <img src="../assets/img/icon/fi-rr-angle-small-down.png"
            class="icon-arrow-price" v-if="showDetail == false"> <img src="../assets/img/icon/fi-rr-angle-small-up.png"
            class="icon-arrow-price" v-else> </span>
      </div>
      <button class="btn btn-primary w-100" type="button"
        :disabled="qtyTotalValidation > orderMaxTicket || qtyTotalValidation == 0" @click="orderTicket()">
        <span>Pesan Tiket</span>
      </button>
    </div>
    <b-modal size="sm" ref="failed-modal" id="failed-modal" centered hide-footer hide-header no-close-on-backdrop
      no-close-on-esc>
      <div class="text-center">
        <img src="../assets/img/warning-icon.png" class="warning-icon mb-2">
        <p class="font-size-20 font-weight-600 mb-2">{{ error?.title }}</p>
        <p class="font-size-12 font-weight-400 mb-5">{{ error?.message }}</p>
        <button type="button" class="btn btn-primary btn-sm" @click="$bvModal.hide('failed-modal')">Ok, Terima
          Kasih</button>
      </div>
    </b-modal>
    <Loading :isLoading="loading" />
  </div>
</template>

<script>
import Loading from "../components/Loading.vue";
import axios from "axios";
import HeaderComp from "../components/HeaderComp.vue";
import { marked } from 'marked';
import { Loader } from '@googlemaps/js-api-loader';

export default {
  components: {
    Loading,
    HeaderComp,
    marked
  },
  data() {
    return {
      testha: '# hello',
      showDetail: false,
      isDisabledRef: false,
      imageBanner: null,
      loading: false,
      dataEvent: {},
      cityName: '',
      markedDesc: null,
      terms: null,
      agent: '',
      ticketData: [],
      orderMaxTicket: 0,
      ticketSummary: {
        qtyTotal: 0,
        priceTotal: 0
      },
      bundling: 0,
      qtyTotalValidation: 0,
      maxTicket: false,
      error: {
        title: "",
        message: ""
      }
    };
  },
  methods: {
    gotoMaps(lat, long) {
      window.location.href = `https://maps.google.com?saddr=Current+Location&daddr=${lat},${long}&mode=driving`;
    },
    getLocation(lat, long) {
      delete axios.defaults.headers.common["Language"];

      const loader = new Loader({
        apiKey: "AIzaSyDM7Nib5JqhZCPGGHZvjSDC1R1a5QJYrk4",
        version: "weekly",
        libraries: ["geocoding"]
      });

      loader.load().then(() => {
        const latlngmaps = new google.maps.LatLng(lat, long);
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({ location: latlngmaps }, (results, status) => {
          if (status === 'OK' && results.length > 0) {
            const addressComponents = results[0].address_components;
            const findCity = addressComponents.find(o =>
              o.types.includes('administrative_area_level_2')
            );

            const cityName = findCity ? findCity.long_name : "Kota tidak ditemukan";
            this.cityName = cityName;
            axios.defaults.headers.common["Language"] = 'id';
            this.getUrlBlob(this.dataEvent?.banner);
          } else {
            axios.defaults.headers.common["Language"] = 'id';
            this.getUrlBlob(this.dataEvent?.banner);
          }
        });
      }).catch((error) => {
        axios.defaults.headers.common["Language"] = 'id';
        this.getUrlBlob(this.dataEvent?.banner);
        this.loading = false;
        console.error('Error loading Google Maps API:', error);
      });
      axios.defaults.headers.common["Language"] = 'id';
      this.getUrlBlob(this.dataEvent?.banner);
      this.loading = false;
    },

    addTicket(key) {
      if (this.ticketData[key].bundlingQty === 0) {
        this.ticketData[key].qty += 1;
        this.ticketSummary.qtyTotal += 1;
      } else {
        this.ticketData[key].qty += 1;
        this.bundling += this.ticketData[key].bundlingQty;
      }

      this.ticketData[key].priceQty += this.ticketData[key].price;
      this.ticketSummary.priceTotal += this.ticketData[key].price;


      let qtytotal = this.ticketSummary.qtyTotal;
      let bundlingtotal = this.bundling;
      this.qtyTotalValidation = qtytotal + bundlingtotal;
    },

    diffTicket(key) {
      if (this.ticketData[key].bundlingQty === 0) {
        this.ticketData[key].qty -= 1;
        this.ticketSummary.qtyTotal -= 1;
      } else {
        this.ticketData[key].qty -= 1;
        this.bundling -= this.ticketData[key].bundlingQty;
      }

      this.ticketData[key].priceQty -= this.ticketData[key].price;
      this.ticketSummary.priceTotal -= this.ticketData[key].price;

      let qtytotal = this.ticketSummary.qtyTotal;
      let bundlingtotal = this.bundling;
      this.qtyTotalValidation = qtytotal + bundlingtotal;

    },
    getEvent(eventURL) {
      this.loading = true;
      axios.get(`/buyers/events/${eventURL}`).then((res) => {
        this.dataEvent = res.data.data;
        this.orderMaxTicket = res.data.data.event_setting.order_max_tickets;
        this.markedDesc = marked(this.dataEvent?.description, { breaks: true });
        this.terms = marked(this.dataEvent?.term_conditions, { breaks: true });
        this.ticketProcessing(this.dataEvent?.event_ticket_categories);
        localStorage.setItem('event_date', this.dataEvent?.start_date);
        localStorage.setItem('event_name', this.dataEvent?.name);
        localStorage.setItem('event_setting', JSON.stringify(this.dataEvent?.event_setting));
        localStorage.setItem('event_banner', this.dataEvent?.banner);
        localStorage.setItem('event_id', this.dataEvent?._id);
        localStorage.setItem('discounts', JSON.stringify(this.dataEvent?.discounts));
        localStorage.setItem('platform_key', this.$route.query.platform_key);
        localStorage.setItem('base_url_list_event', `${window.location.origin}/event?platform_key=` + this.$route.query.platform_key);



        // if (this.$route.query.auth_token) {
        //   const decodingToken = decodeURIComponent(this.$route.query.auth_token);

        //   const jsonToken = JSON.parse(decodingToken);
        //   console.log(decodingToken)
        //   console.log(jsonToken)
        //   if (jsonToken?.step == 'order' && jsonToken?.token) {
        //     localStorage.setItem('token', jsonToken?.token);
        //     axios.defaults.headers.common["Authorization"] = "Bearer " + jsonToken?.token;
        //     this.$router.push('/payment-method');
        //   } else if (jsonToken?.step == 'payment' && jsonToken?.token) {
        //     localStorage.setItem('token', jsonToken?.token);
        //     axios.defaults.headers.common["Authorization"] = "Bearer " + jsonToken?.token;
        //     this.$router.push('/payment-detail');
        //   }
        // }
        // this.loading = false;
      }).catch((error) => {
        this.loading = false;
        if (error?.response?.data?.title || error?.response?.data?.message) {
          this.error.title = error?.response?.data?.title;
          this.error.message = error?.response?.data?.message;
          this.$bvModal.show('failed-modal');
        } else {
          this.loading = false;
          this.$router.push('/busy-server');
        }
      });
    },
    getUrlBlob(val) {
      if (val) {
        let base_url = axios.defaults.baseURL;
        const sig_url = base_url + '/buyers' + val;
        const token = localStorage.getItem("token");
        const sigRequest = fetch(sig_url, {
          method: "GET", // or 'PUT'
          // headers: {
          //   authorization: "Bearer " + token,
          // },
        }).then(response => response.blob()).then(response => {
          // this.loading = false;
          const fr = new FileReader();
          fr.onload = () => {
            const dataURL = fr.result;
            this.imageBanner = dataURL;
            localStorage.setItem('banner_new', JSON.stringify(dataURL));
            // window.localStorage.setItem('banner_pending', JSON.stringify(dataURL));
            const imageUrl = dataURL;
            // Create an image object to load the background image
            const image = new Image();
            image.src = imageUrl;

            setTimeout(() => {
              const naturalWidth = image.width;
              const naturalHeight = image.height;

              let currentWidth = document.getElementById('bg-event').offsetWidth;

              let currentHeight;
              if (naturalWidth > currentWidth) {
                const ratio = naturalWidth / currentWidth;
                currentHeight = naturalHeight / ratio;
              } else {
                const ratio = currentWidth / naturalWidth;
                currentHeight = naturalHeight * ratio;
              }

              if (currentHeight) {
                const marginTop = currentHeight - 50;
                document.getElementById("floating-button-event").style.marginTop = marginTop + "px";
                setTimeout(() => { this.loading = false; }, 1000);
              } else {
                this.loading = false;
              }
            }, 1000);
          }
          fr.readAsDataURL(response);
        }).catch((error) => {
          this.loading = false;
        });
      }
    },
    ticketProcessing(data) {
      let ticketDatas = localStorage.getItem('ticketData');
      let lastPathData = localStorage.getItem('lastPathData');
      let dataOrder = null;
      if (ticketDatas && lastPathData == 'choose-ticket') {
        dataOrder = JSON.parse(ticketDatas);
      }

      const now = new Date();
      const startTime = new Date('2024-12-12T00:00:00');
      const endTime = new Date('2024-12-12T23:59:00');

      data.forEach((item) => {
        if (
          item.bundle_quantity === 24 &&
          (now < startTime || now > endTime)
        ) {
          return;
        }

        let dataFinder = dataOrder?.find(o => o.category_id == item?._id);
        let qtyOrdered = 0;
        let priceOrdered = 0;
        if (dataFinder) {
          if (dataFinder.qty <= item?.available_quantity) {
            qtyOrdered = dataFinder.qty;
            priceOrdered = dataFinder.priceQty;
            this.ticketSummary.priceTotal += dataFinder.priceQty;
            this.ticketSummary.qtyTotal += dataFinder.qty;
          }
        }

        this.ticketData.push({
          category_id: item?._id,
          category: item?.name,
          price: item?.price,
          qtySold: item?.available_quantity,
          description: item?.description,
          qty: qtyOrdered,
          priceQty: priceOrdered,
          sale_end_date: item?.sale_end_date,
          ticket_type: item?.ticket_type,
          bundlingQty: item?.bundle_quantity == undefined ? 0 : item?.bundle_quantity
        });
      });

      this.getLocation(this.dataEvent?.latitude, this.dataEvent?.longitude);
    },
    orderTicket() {
      localStorage.setItem('ticketData', JSON.stringify(this.ticketData));
      localStorage.setItem('lastPathData', 'choose-ticket');
      localStorage.setItem('ticketSummary', JSON.stringify(this.ticketSummary));
      localStorage.setItem('stepOrder', 'ticket');
      let event_id = localStorage.getItem('event_id');
      axios.post("/buyers/orders/check-room", { event_id: event_id }).then((res) => {
        localStorage.setItem('form_id', res.data.data.form_id);
        this.$router.push('/detail-order');
      }).catch((error) => {
        console.log(error)
        this.$router.push('/busy-server');
      })
    },
    markData(data) {
      return marked(data, { breaks: true });
    }
  },
  mounted() {
    console.log("INI ROUTE => " + JSON.stringify(this.$route.query.platform_key))
    if (this.$route.params.name) {
      if (this.$route.query.platform_key) {
        this.getEvent(this.$route.params.name);
        localStorage.setItem('currentPath', '/event');
        localStorage.setItem('url_event', this.$route.params.name);
        localStorage.setItem('base_url_event', window.location.href);
        //window.localStorage.clear();
      } else {
        this.$router.replace({ name: "ChooseTicket", params: { name: this.$route.params.name }, query: { platform_key: localStorage.getItem('platform_key') } })
        this.getEvent(this.$route.params.name);
        localStorage.setItem('currentPath', '/event');
        localStorage.setItem('url_event', this.$route.params.name);
        localStorage.setItem('base_url_event', window.location.href);

      }
    } else {
      this.$bvModal.show('failed-modal')
      this.error.title = "Event Tidak Ditemukan";
      this.error.message = "Silahkan cek kembali event dan coba lagi";
      //window.localStorage.clear();
    }
  },
};
</script>

<style lang="scss" scoped>
.ticket-detail-number span /deep/ p {
  margin-bottom: 0 !important;
}

.ticket-icon {
  width: 16px;
}

.ticket-grey-icon {
  width: 12px;
}

.floating-button-detail {
  margin-top: 50vw;
  margin-bottom: 2vw;
}

.disabled-qty {
  color: #647179 !important;
  cursor: not-allowed;
}

.rounded-footer {
  border-radius: 16px 16px 0px 0px !important;
}

.message-claimed {
  display: flex;
  justify-content: space-between;
  font-size: 0.625rem;
}

.message-claimed img {
  width: 3.59vw;
  height: 3.59vw;
}

.message-claimed span {
  font-size: 0.625rem;
}

.total-ticket {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: #000000;
  font-weight: 400;
}

.container-ticket i {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
}

.container-ticket {
  padding: 2vw;
  font-size: 0.75rem;
}

.card-summary {
  padding: 2vw;
  border: 1px solid #E7ECF4;
  border-radius: 8px;
}

.summary-detail {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
}

.summary-detail-close {
  font-size: 1rem;
  color: #8E8E93;
}

.summary-qty {
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
}

.link-text {
  text-decoration: underline;
}

.disclaimer-text {
  padding: 2vw;
  font-size: 0.625rem;
}

.text-qty {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: -0.5px;
  text-align: center;
  display: inline-flex;
}

.icon-arrow {
  width: 12px;
  align-self: center;
}

.icon-arrow-price {
  width: 16px;
  align-self: center;
}

.icon-qty {
  font-size: 0.9rem;
  letter-spacing: -0.5px;
  display: inline-flex;
}

.icon-qty-plus {
  margin-top: 3px;
  font-size: 0.75rem;
}

.ticket-qty {
  font-size: 0.75rem;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.ticket-detail-name {
  font-size: 0.75rem;
}

.ticket-detail-number {
  font-size: 0.625rem;
}

.ticket-detail {
  width: 80%;
}

.cat-ticket {
  border: 1px solid #F2F5F7;
  padding: 2vw;
  display: flex;
  justify-content: space-between;
}

.sub-sub-title {
  border-bottom: none !important;
  font-size: 0.75rem;
  /* font-weight: bold;
  margin: 0 0 30px; */
  cursor: pointer;
}

.sub-sub-title span {
  vertical-align: middle;
}

.header-title {
  padding: 2vw 2vw;
  background: #F2F5F7;
  border-radius: 4px;
}

.desc-card {
  background: #F2F5F7;
  font-size: 0.75rem;
  border-radius: 8px;
  padding: 8px;
}

.desc-title {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 16px;
}

.address-redirect {
  font-size: 16px;
}

.address-redirect img {
  width: 16px;
}

.address-detail {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75vw;
  line-height: 14.52px;
  font-size: 0.75rem;
  font-weight: 600;
  white-space: nowrap;
}

.address-city {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75vw;
  color: #8E8E93;
  line-height: 14.52px;
  font-size: 0.75rem;
  font-weight: 400;
  white-space: nowrap;
}

.maps-img img {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.title-event {
  font-size: 1rem;
  font-weight: 600;
}

.event-calendar {}

.event-calendar img {
  width: 0.65rem;
  height: 0.65rem;
}

.event-calendar span {
  font-size: 0.75rem;
  font-weight: 400;
  vertical-align: bottom;
}

.img-banner-bg {
  //background-image: url("../assets/img/banner.png");
  background-repeat: no-repeat;
  background-size: 100vw;
}

.container-content {
  background-color: white;
  padding: 4vw;
  border-radius: 16px 16px 0px 0px;
}

.card-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: 10px;
}

.footer-form {
  width: 100%;
  padding: 4vw 4vw;
  box-shadow: 4px 4px 20px 0px #00000033;
}
</style>
