var render = function () {
  var _vm$error, _vm$error2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-page"
  }, [_c('HeaderComp', {
    attrs: {
      "title": "",
      "url": "/",
      "isBackToEvent": ""
    }
  }), _c('div', {
    staticClass: "card-page"
  }, [_c('div', {
    staticClass: "form-order"
  }, [_c('div', {
    staticClass: "title-page mb-3"
  }, [_vm._v("Lihat E-Ticket")]), _c('div', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "form-floating"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$v.formOrderer.ticket_code.$model,
      expression: "$v.formOrderer.ticket_code.$model"
    }],
    class: ['form-control', {
      'border-danger': _vm.$v.formOrderer.ticket_code.$error
    }],
    attrs: {
      "type": "text",
      "id": "name",
      "placeholder": "Masukkan Nomor Tiket"
    },
    domProps: {
      "value": _vm.$v.formOrderer.ticket_code.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.formOrderer.ticket_code, "$model", $event.target.value);
      }
    }
  }), _vm._m(0)]), _vm.$v.formOrderer.ticket_code.$error ? _c('div', {
    staticClass: "text-danger font-size-10 mt-1"
  }, [!_vm.$v.formOrderer.ticket_code.required ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Nama tiket tidak boleh kosong")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "form-floating"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$v.formOrderer.ticket_email.$model,
      expression: "$v.formOrderer.ticket_email.$model"
    }],
    class: ['form-control', {
      'border-danger': _vm.$v.formOrderer.ticket_email.$error
    }],
    attrs: {
      "type": "email",
      "id": "email",
      "placeholder": "Masukkan Alamat Email Anda"
    },
    domProps: {
      "value": _vm.$v.formOrderer.ticket_email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.formOrderer.ticket_email, "$model", $event.target.value);
      }
    }
  }), _vm._m(1)]), _vm.$v.formOrderer.ticket_email.$error ? _c('div', {
    staticClass: "text-danger font-size-10 mt-1"
  }, [!_vm.$v.formOrderer.ticket_email.required ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Alamat email tidak boleh kosong")]) : _vm._e(), !_vm.$v.formOrderer.ticket_email.email ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Format alamat email tidak valid")]) : _vm._e()]) : _vm._e()])]), _c('div', {
    staticClass: "cp-card"
  }, [_c('div', {
    staticClass: "cp-card-title"
  }, [_vm._v("Contact person")]), _c('div', {
    staticClass: "cp-card-val"
  }, [_c('img', {
    staticClass: "cp-card-icon me-2",
    attrs: {
      "src": require("../assets/img/instagram.png")
    }
  }), _c('span', {
    staticClass: "cp-card-val me-2"
  }, [_vm._v("@metalgo.id")]), _c('img', {
    staticClass: "cp-card-icon cursor-pointer",
    attrs: {
      "src": require("../assets/img/icon-copy.png")
    },
    on: {
      "click": function ($event) {
        return _vm.copyData('https://www.instagram.com/metalgo.id');
      }
    }
  })]), _c('img', {
    staticClass: "cp-card-icon-sep",
    attrs: {
      "src": require("../assets/img/icon-separator.png")
    }
  }), _c('div', {
    staticClass: "cp-card-val"
  }, [_c('img', {
    staticClass: "cp-card-icon me-2",
    attrs: {
      "src": require("../assets/img/icon-wa.png")
    }
  }), _c('span', {
    staticClass: "cp-card-val me-2"
  }, [_vm._v("+62812 1733 3188")]), _c('img', {
    staticClass: "cp-card-icon cursor-pointer",
    attrs: {
      "src": require("../assets/img/icon-copy.png")
    },
    on: {
      "click": function ($event) {
        return _vm.copyData('https://wa.me/6281217333188');
      }
    }
  })])])]), _c('div', {
    staticClass: "footer-form"
  }, [_c('button', {
    staticClass: "btn btn-primary w-100",
    attrs: {
      "type": "button",
      "disabled": _vm.$v.formOrderer.$invalid
    },
    on: {
      "click": function ($event) {
        return _vm.checkTicket();
      }
    }
  }, [_vm._v(" Lihat E-Ticket ")])]), _c('b-modal', {
    ref: "failed-modal",
    attrs: {
      "size": "sm",
      "id": "failed-modal",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "warning-icon mb-2",
    attrs: {
      "src": require("../assets/img/warning-icon.png")
    }
  }), _c('p', {
    staticClass: "font-size-20 font-weight-600 mb-2"
  }, [_vm._v(_vm._s((_vm$error = _vm.error) === null || _vm$error === void 0 ? void 0 : _vm$error.title))]), _c('p', {
    staticClass: "font-size-12 font-weight-400 mb-5"
  }, [_vm._v(_vm._s((_vm$error2 = _vm.error) === null || _vm$error2 === void 0 ? void 0 : _vm$error2.message))]), _c('button', {
    staticClass: "btn btn-primary btn-sm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('failed-modal');
      }
    }
  }, [_vm._v("Ok, Terima Kasih")])])]), _c('Loading', {
    attrs: {
      "isLoading": _vm.loading
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    attrs: {
      "for": "name"
    }
  }, [_vm._v("Nomor Tiket "), _c('span', {
    staticClass: "text-danger required-label"
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Alamat Email "), _c('span', {
    staticClass: "text-danger required-label"
  }, [_vm._v("*")])]);
}]

export { render, staticRenderFns }