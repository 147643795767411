<template>
  <div class="vld-parent">
    <loading :active="isLoading" :is-full-page="isFullpage" :loader="loader" color="#1C6DB8" />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "LoaderComponent",
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isFullpage: {
      type: Boolean,
      required: false,
      default: true
    },
    loader: {
      type: String,
      required: false,
      default: "dots"
    },
  },
  components: {
    Loading,
  }
};
</script>