<template>
  <div id="app">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app"
};
</script>