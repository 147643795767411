<template>
    <div class="container-page">
        <HeaderComp title="" url="#" noback isBackToHome v-if="status_payment == 'waiting'" />
        <div class="card-page">
            <div class="form-order">
                <div :class="['title-page mb-3', { 'mt-3': status_payment == 'pending' }]">Pembayaran</div>
                <div class="card-shadow mb-3">
                    <div class="content-space-between event-calendar">
                        <div><img src="../assets/img/icon/fi-rr-calendar-grey.png" class="me-2 icon-12" /><span>Tanggal
                                Event</span></div> <span>{{
            formatDateTimesDay(eventDate) }}</span>
                    </div>
                    <div class="title-event content-space-between">
                        <img :src="imgBanner" class="banner-small me-3" v-if="imgBanner"> <span class="w-100">{{
            eventName }}</span>
                    </div>
                    <div class="content-space-between">
                        <p class="m-0 text-black font-size-12">Status Pembayaran</p>
                        <p class="m-0"><span class="badges-timer bg-tertiery m-0">{{ status_order }}</span></p>
                    </div>
                </div>
                <div class="card-shadow-total mb-3">
                    <div class="container-ticket">
                        <img src="../assets/img/icon/fi-rr-ticket-grey.png" class="icon-12 me-1"> <span
                            class="font-weight-600">Tiket Kamu</span>
                        <div class="mt-2">
                            <div v-for="(item, key) in groupedOrderSummary" :key="key">
                                <div class="sub-total-ticket">
                                    <span>{{ item?.category_name }}</span>
                                    <span>{{ formatCurrency((item?.sum_price / item?.sum_quantity)) }}</span>
                                </div>
                                <div class="total-ticket mb-1">
                                    <span class="font-weight-700 font-size-12">{{ item?.sum_quantity }}x {{
            item?.category_name }}</span>
                                    <span class="font-weight-700 font-size-12">{{ formatCurrency(item?.sum_price)
                                        }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="total-ticket">
                            <span class="font-weight-400 font-size-12">Biaya Layanan</span>
                            <span class="font-weight-600 font-size-12">{{ formatCurrency(admin_fee) }}</span>
                        </div>
                        <div class="total-ticket admin-fee mb-3">
                            <span class="font-weight-400 font-size-12">Diskon</span>
                            <span class="font-weight-600 font-size-12">{{ formatCurrency(discount) }}</span>
                        </div>
                        <div class="summary-qty mt-2">
                            <span>Total Pembayaran</span>
                            <span class="font-weight-600">{{ formatCurrency(total_price)
                                }}</span>
                        </div>
                    </div>
                </div>
                <div class="card-disclaimer content-space-between mb-2" v-if="message_order">
                    <img src="../assets/img/icon/fi-rr-hourglass-end.png" class="icon-12 me-1"> <span
                        class="font-size-10 font-weight-400 text-black w-100">{{ message_order }}</span>
                </div>
            </div>
        </div>
        <Loading :isLoading="loading" />
        <b-modal size="sm" ref="failed-modal" id="failed-modal" centered hide-footer hide-header no-close-on-backdrop
            no-close-on-esc>
            <div class="text-center">
                <img src="../assets/img/warning-icon.png" class="warning-icon mb-2">
                <p class="font-size-20 font-weight-600 mb-2">{{ error?.title }}</p>
                <p class="font-size-12 font-weight-400 mb-5">{{ error?.message }}</p>
                <button type="button" class="btn btn-primary btn-sm" @click="$bvModal.hide('failed-modal')">Ok,
                    Terima
                    Kasih</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Loading from "../components/Loading.vue";
// import axios from "axios";
import HeaderComp from "../components/HeaderComp.vue";
import axios from "axios";
export default {
    components: {
        Loading,
        HeaderComp,
    },
    data() {
        return {
            loading: false,
            error: {
                title: "",
                message: ""
            },
            imgBanner: null,
            eventDate: localStorage.getItem('event_date'),
            eventName: "",
            order_id: localStorage.getItem('order_id'),
            platform_key: localStorage.getItem('platform_key'),
            admin_fee: 0,
            total_price: 0,
            discount: 0,
            order_summary: [],
            intervalPaymentCC: null,
            intervalToken: null,
            intervalTokenSend: null,
            status_order: "",
            message_order: "",
            status_payment: "",
            type: ""
        };
    },
    methods: {
        processCC(err_code, txn_status, merchant_id, trans_id) {
            axios.get(`/buyers/orders/notification/cc?merchant_trans_id=${merchant_id}&faspay_trans_id=${trans_id}`).then((res) => {
            }).catch((error) => {
                this.loading = false;
                if (error?.response?.data?.title || error?.response?.data?.message) {
                    this.error.title = error?.response?.data?.title;
                    this.error.message = error?.response?.data?.message;
                    this.$bvModal.show('failed-modal');
                } else {
                    this.loading = false;
                    this.$router.push('/busy-server');
                }
            });
        },
        getDetailOrder() {
            this.loading = true;
            axios.get("/buyers/orders/detail").then((res) => {
                this.getUrlBlob(res.data.data.event_id.banner);
                this.eventName = res.data.data.event_id.name;
                this.order_summary = res.data.data.order_summary;
                this.admin_fee = res.data.data.transaction_id.admin_fee;
                this.total_price = res.data.data.transaction_id.total_amount;
                this.loading = false;
            }).catch((error) => {
                // console.log(error)
                this.loading = false;
                if (error?.response?.data?.title || error?.response?.data?.message) {
                    this.error.title = error?.response?.data?.title;
                    this.error.message = error?.response?.data?.message;
                    this.$bvModal.show('failed-modal');
                } else {
                    this.loading = false;
                    this.$router.push('/busy-server');
                }
            });
        },
        getUrlBlob(val) {
            if (val) {
                let base_url = axios.defaults.baseURL;
                const sig_url = base_url + '/buyers' + val;
                const token = localStorage.getItem("token");
                const sigRequest = fetch(sig_url, {
                    method: "GET", // or 'PUT'
                    // headers: {
                    //   authorization: "Bearer " + token,
                    // },
                }).then(response => response.blob()).then(response => {
                    this.loading = false;
                    const fr = new FileReader();
                    fr.onload = () => {
                        const dataURL = fr.result;
                        this.imgBanner = dataURL;
                    }
                    fr.readAsDataURL(response);
                }).catch((error) => {
                    this.loading = false;
                });
            }
        },
        checkPayment() {
            axios.get("/buyers/orders/payments/detail").then((res) => {
                let status = res?.data?.data?.status;
                if (status !== 'PENDING') {
                    clearInterval(this.intervalPaymentCC);
                    if (status == 'SUCCESS') {
                        const platform = localStorage.getItem('platform_key');
                        const url_base = localStorage.getItem('base_url_event');
                        localStorage.clear();
                        localStorage.setItem('platform_key', platform);
                        localStorage.setItem('base_url_event', url_base);
                        this.loading = false;
                        localStorage.setItem('success_payment', 'Selamat, pembayaran Tiket Anda menggunakan Kartu Kredit sudah berhasil!');
                        this.$router.push({ name: "SuccessPayment", query: { type: this.type } });
                    } else if (status == 'EXPIRED') {
                        const platform = localStorage.getItem('platform_key');
                        const url_base = localStorage.getItem('base_url_event');
                        localStorage.clear();
                        localStorage.setItem('platform_key', platform);
                        localStorage.setItem('base_url_event', url_base);
                        this.loading = false;
                        localStorage.setItem('message_failed_payment', 'Mohon Maaf, waktu pembayaran tiket Anda menggunakan Kartu Kredit sudah melewati batas waktu.');
                        localStorage.setItem('title_failed_payment', 'Pembayaran Kadaluwarsa');
                        this.$router.push({ name: "FailedPayment", query: { type: this.type } });
                    } else if (status == 'FAILED') {
                        const platform = localStorage.getItem('platform_key');
                        const url_base = localStorage.getItem('base_url_event');
                        localStorage.clear();
                        localStorage.setItem('platform_key', platform);
                        localStorage.setItem('base_url_event', url_base);
                        this.loading = false;
                        localStorage.setItem('message_failed_payment', 'Mohon Maaf, pembayaran tiket Anda menggunakan Kartu Kredit tidak berhasil!');
                        localStorage.setItem('title_failed_payment', 'Pembayaran Gagal');
                        this.$router.push({ name: "FailedPayment", query: { type: this.type } });
                    } else {
                        const platform = localStorage.getItem('platform_key');
                        const url_base = localStorage.getItem('base_url_event');
                        localStorage.clear();
                        localStorage.setItem('platform_key', platform);
                        localStorage.setItem('base_url_event', url_base);
                        this.loading = false;
                        localStorage.setItem('message_failed_payment', 'Mohon Maaf, pembayaran tiket Anda menggunakan Kartu Kredit tidak berhasil!');
                        localStorage.setItem('title_failed_payment', 'Pembayaran Gagal');
                        this.$router.push({ name: "FailedPayment", query: { type: this.type } });
                    }
                }
            }).catch((error) => {
                if (error?.response?.data?.title || error?.response?.data?.message) {
                    this.error.title = error?.response?.data?.title;
                    this.error.message = error?.response?.data?.message;
                    this.$bvModal.show('failed-modal');
                } else {
                    this.$router.push('/busy-server');
                }
            });
        },
        getDiscount() {
            axios.get("/buyers/orders/payments/detail").then((res) => {
                this.discount = res?.data?.data?.discount;
            });
        }
    },
    computed: {
        groupedOrderSummary() {
            const grouped = {};

            this.order_summary.forEach(item => {
            if (grouped[item.category_id]) {
                grouped[item.category_id].sum_quantity += item.sum_quantity;
                grouped[item.category_id].sum_price += item.sum_price;
            } else {
                grouped[item.category_id] = { ...item };
            }
            });

            return Object.values(grouped);
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalPaymentCC);
        clearInterval(this.intervalToken);
        clearInterval(this.intervalTokenSend);
    },
    created() {
        let likes = this;
        this.intervalPaymentCC = setInterval(() => {
            likes.checkPayment();
        }, 5000);
    },
    mounted() {
        let banner = window.localStorage.getItem('banner_pending');

        if (banner) {
            let bannerSet = JSON.parse(window.localStorage.getItem('banner_pending'));
            this.imgBanner = bannerSet;
        }

        localStorage.setItem('currentPath', '/check-status');
        localStorage.setItem('statusCC', window.location.href);
        const err_code = this.$route.query.ERR_CODE;
        const txn_status = this.$route.query.TXN_STATUS;
        const merchant_id = this.$route.query.MERCHANT_TRANID;
        const trans_id = this.$route.query.TRANSACTIONID;
        this.loading = true;
        const auth_token = this.$route.params.auth_token;

        const token_order = auth_token;
        if (token_order) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token_order;
        }

        setTimeout(() => {
            this.getDetailOrder();
            this.getDiscount();
            this.loading = false;
        }, 2000);

        if (merchant_id && trans_id) {
            this.status_order = "Diproses";
            this.message_order = "Harap tunggu! Pembayaran sedang diproses,cek berkala email Anda.";
            setTimeout(() => {
                this.processCC(err_code, txn_status, merchant_id, trans_id);
            }, 1000);

            this.status_payment = 'pending';
            this.type = "2";
        } else {
            this.status_order = "Menunggu Pembayaran";
            this.message_order = "";
            this.status_payment = 'waiting';
            this.type = "1";
        }

    },
};
</script>

<style lang="scss" scoped>
.warning-icon {
    width: 12%;
    height: 12%;
}

.container-page {
    max-width: 480px !important;
    margin: auto !important;
    width: 100% !important;
}

.admin-fee {
    padding-bottom: 3%;
    border-bottom: 1px solid #E7ECF4;
}

.card-disclaimer {
    background: #F5F7FA;
    border-radius: 8px;
    padding: 3%;
}

.card-disclaimer i {
    font-size: 0.75rem;
    color: #647179;
}

.payment-name {
    text-align: left;
}

.bg-logo-radio {
    background-color: #ffffff;
    width: fit-content;
    height: fit-content;
    border-radius: 6px;
}

.logo-radio {
    width: 16px;
    height: 5px;
}

.card-payment-method {
    background: #fff;
    border: 1px solid #D2D7DA;
    padding: 4%;
    border-radius: 8px;
}

.sub-title-container {
    font-size: 0.625rem;
    font-weight: 400;
    color: #000;
}

.disabled-qty {
    color: #647179 !important;
    cursor: not-allowed;
}

.rounded-footer {
    border-radius: 16px 16px 0px 0px !important;
}

.message-claimed {
    display: flex;
    justify-content: space-between;
    font-size: 0.625rem;
}

.message-claimed img {
    width: 3.59%;
    height: 3.59%;
}

.message-claimed span {
    font-size: 0.625rem;
}

.total-ticket {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: #000000;
    font-weight: 400;
}

.sub-total-ticket {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: #647179;
    font-weight: 400;
}

.container-ticket i {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    display: inline-block;
}

.container-ticket {
    font-size: 0.75rem;
}

.container-ticket-card {
    padding-top: 9px;
    font-size: 0.75rem;
    color: #000;
}

.container-ticket-card i {
    font-size: 0.75rem;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    display: inline-block;
    color: #AEAEB2;
}

.card-summary {
    padding: 2%;
    border: 1px solid #E7ECF4;
    border-radius: 8px;
}

.summary-detail {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
}

.summary-detail i {
    font-size: 1rem;
    color: #8E8E93;
}

.summary-qty {
    display: flex;
    justify-content: space-between;
    font-size: 0.85rem;
}

.link-text {
    text-decoration: underline;
}

.disclaimer-text {
    padding: 2%;
    font-size: 0.625rem;
}

.text-qty {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: -0.5px;
    text-align: center;
    display: inline-flex;
}

.icon-arrow {
    font-size: 0.75rem;
    line-height: 12px;
    align-self: center;
}

.icon-qty {
    margin-top: 3px;
    font-size: 0.75rem;
    line-height: 13px;
    letter-spacing: -0.5px;
    display: inline-flex;
}

.ticket-qty {
    font-size: 0.75rem;
    align-self: center;
}

.ticket-detail-name {
    color: #636366;
}

.cat-visit {
    border: 1px solid #F2F5F7;
    border-radius: 8px;
    padding: 3%;
    display: flex;
    justify-content: space-between;
}

.sub-sub-title {
    border-bottom: none !important;
    font-size: 0.625rem;
    /* font-weight: bold;
  margin: 0 0 30px; */
    cursor: pointer;
}

.sub-sub-title span {
    vertical-align: middle;
}

.header-title {
    padding: 2% 2%;
    background: #F2F5F7;
    border-radius: 4px;
}

.desc-card {
    background: #F2F5F7;
    border-radius: 8px;
    padding: 4%;
    color: #232323;
}

.desc-title {
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 16px;
}

.address-redirect {
    font-size: 16px;
}

.address-detail {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 75%;
    line-height: 14.52px;
    font-size: 0.75rem;
    font-weight: 600;
    white-space: nowrap;
}

.address-city {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 75%;
    color: #8E8E93;
    line-height: 14.52px;
    font-size: 0.75rem;
    font-weight: 400;
    white-space: nowrap;
}

.maps-img img {
    width: 32px;
    height: 32px;
    border-radius: 8px;
}

.title-event {
    font-size: 0.75rem;
    font-weight: 400;
    padding-top: 9px;
    padding-bottom: 9px;
    border-bottom: 1px solid #F2F2F2;
    margin-bottom: 9px;
}

.banner-small {
    border: 1px solid #E5E5EA;
    border-radius: 8px;
    width: 77px;
    height: 31px;
}

.event-calendar {
    font-size: 0.75rem;
    padding-bottom: 9px;
    border-bottom: 1px solid #F2F2F2;
}

.event-calendar span {
    font-weight: 400;
    color: #000;
}

.event-calendar i {
    color: #AEAEB2;
}

.img-banner-bg {
    background-image: url("../assets/img/banner.png");
    background-repeat: no-repeat;
    background-size: 100% 51%;
}

.container-content {
    background-color: white;
    padding: 4%;
    margin-top: 49%;
    border-radius: 16px 16px 0px 0px;
}

.card-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0% 4.1% 4.1% 4.1%;
}

.footer-form {
    width: 100%;
    padding: 4% 4%;
    box-shadow: 4px 4px 20px 0px #00000033;
}
</style>