var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-page"
  }, [_c('div', {
    staticClass: "card-page"
  }, [_c('div', {
    staticClass: "img-banner-bg h-100"
  }, [_vm._m(0), _c('div', {
    staticClass: "footer-form"
  }, [_c('button', {
    staticClass: "btn btn-white text-primary w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.retryHit
    }
  }, [_c('span', [_vm._v("Muat Ulang")])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "center-content success-message h-100"
  }, [_c('img', {
    staticClass: "mb-2",
    attrs: {
      "src": require("../assets/img/icon-busy.png")
    }
  }), _c('p', {
    staticClass: "font-size-24 font-weight-500 text-dark text-center mb-2"
  }, [_vm._v("Aktivitas pada Sistem Tinggi")]), _c('p', {
    staticClass: "font-size-14 font-weight-400 text-desc-busy text-center me-3 ms-3"
  }, [_vm._v("Sistem kami saat ini sedang mengalami lonjakan aktivitas yang luar biasa. Coba lagi beberapa saat.")])]);
}]

export { render, staticRenderFns }