var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('meta', {
    attrs: {
      "name": "viewport",
      "content": "width=device-width, initial-scale=1.0"
    }
  }), _c('router-view')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }