<template>
    <div class="container-page">
        <HeaderComp title="" url="/e-ticket" v-if="isHeader" />
        <div class="card-page mt-5">
            <div class="bg-white p-3">
                <p class="font-size-14 font-weight-400 text-black text-center mb-3 ps-5 pe-5">Upload Selfie untuk Check
                    In via
                    Face Recognition</p>
                <div class="foto-empty col-centered mb-3" v-if="photo == null">
                    <div class="upload-btn-wrapper">
                        <button class="btn btn-primary button-upload">Upload Photo</button>
                        <input type="file" name="myfile" ref="photos" @change="uploadPhoto" accept=".png, .jpg, .jpeg" capture="user"/>
                    </div>
                </div>
                <div class="foto-filled col-centered mb-3" :style="{ 'background-image': 'url(' + imageSelfie + ')' }"
                    v-else>
                    <div class="upload-btn-wrapper">
                        <button class="btn btn-delete text-danger button-upload" @click="deletePhoto">Delete
                            Photo</button>
                    </div>
                </div>
                <div class="center-content">
                    <div class="card-event w-100 mt-2">
                        <div class="mb-2">
                            <p class="m-0 cat-idt">Nama Pengunjung</p>
                            <p class="m-0 font-size-14 font-weight-600">{{ detail?.ticket_attendee_name }}</p>
                        </div>
                        <div class="mb-2">
                            <p class="m-0 cat-idt">Tiket Kategori</p>
                            <p class="m-0 font-size-14 font-weight-600">{{ detail?.ticket_category_name }}</p>
                        </div>
                        <div class="mb-2">
                            <p class="m-0 cat-idt">Event Schedule</p>
                            <p class="m-0 font-size-14 font-weight-600">{{ detail?.event_start_date }}</p>
                        </div>
                        <div class="mb-3">
                            <p class="m-0 cat-idt">Lokasi</p>
                            <p class="m-0 font-size-12 font-weight-600">{{ detail?.event_address }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center p-3">
            <img src="../assets/img/logo-metalgo-ticket.png" class="logo-metalgo-footer">
        </div>
        <b-modal size="sm" ref="failed-modal" id="failed-modal" centered hide-footer hide-header no-close-on-backdrop
            no-close-on-esc>
            <div class="text-center">
                <img src="../assets/img/warning-icon.png" class="warning-icon mb-2">
                <p class="font-size-20 font-weight-600 mb-2">{{ error?.title }}</p>
                <p class="font-size-12 font-weight-400 mb-5">{{ error?.message }}</p>
                <button type="button" class="btn btn-primary btn-sm" @click="$bvModal.hide('failed-modal')">Ok, Terima
                    Kasih</button>
            </div>
        </b-modal>
        <Loading :isLoading="loading" />
    </div>
</template>

<script>
import Loading from "../components/Loading.vue";
import FilePicker from "../components/FilePicker.vue";
import axios from "axios";
import HeaderComp from "../components/HeaderComp.vue";
export default {
    components: {
        Loading,
        HeaderComp,
        FilePicker
    },
    data() {
        return {
            showDetail: false,
            isDisabledRef: false,
            loading: false,
            detail: null,
            photo: null,
            imageSelfie: "",
            isHeader: localStorage.getItem('lastPathData'),
            token: null,
            error: {
                title: "",
                message: ""
            }
        };
    },
    methods: {
        getDetail() {
            this.loading = true;
            if (this.token) {
                axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
            }
            axios.get("/ticket/detail").then((res) => {
                this.detail = res?.data?.data;
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                if (error?.response?.data?.title || error?.response?.data?.message) {
                    this.error.title = error?.response?.data?.title;
                    this.error.message = error?.response?.data?.message;
                    this.$bvModal.show('failed-modal');
                } else {
                    this.$router.push('/busy-server');
                }
            });
        },
        uploadPhoto(obj) {
            this.loading = true;
            let face = null;
            if (obj.type == "change") {
                face = this.$refs.photos.files[0];
            } else {
                face = obj;
            }

            let formData = new FormData();
            formData.append("file", face);
            let like = this;
            axios.post("/ticket/face", formData).then((res) => {
                setTimeout(() => { like.getFR() }, 2000);
            }).catch((error) => {
                this.loading = false;
                if (error?.response?.data?.title || error?.response?.data?.message) {
                    this.error.title = error?.response?.data?.title;
                    this.error.message = error?.response?.data?.message;
                    this.$bvModal.show('failed-modal');
                } else {
                    this.$router.push('/busy-server');
                }
            });
        },
        async getFR() {
            this.loading = true;
            let base_url = axios.defaults.baseURL;
            let sig_url = base_url + '/ticket/face';
            try {
                const sigRequest = await fetch(sig_url, {
                    method: "GET", // or 'PUT'
                    headers: {
                        authorization: "Bearer " + this.token,
                    },
                });

                if (sigRequest?.status == 200) {
                    const blobImg = await sigRequest.blob();
                    this.imageSelfie = window.URL.createObjectURL(blobImg);
                    this.photo = blobImg;
                    this.loading = false;
                } else {
                    this.photo = null;
                    this.imageSelfie = "";
                    this.loading = false;
                }
            }
            catch (error) {
                console.error("Error:", error);
            }
        },
        deletePhoto() {
            this.loading = true;
            if (this.token) {
                axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
            }
            axios.delete("/ticket/face").then((res) => {
                this.photo = null;
                this.imageSelfie = "";
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                if (error?.response?.data?.title || error?.response?.data?.message) {
                    this.error.title = error?.response?.data?.title;
                    this.error.message = error?.response?.data?.message;
                    this.$bvModal.show('failed-modal');
                } else {
                    this.$router.push('/busy-server');
                }
            });
        }
    },
    computed: {

    },
    mounted() {
        if (this.$route.query.ticket_token) {
            this.token = this.$route.query.ticket_token;
        } else {
            this.token = localStorage.getItem('ticket_token');
        }

        const likes = this;

        setTimeout(() => {
            likes.getDetail();
            likes.getFR();
        }, 1000);

        localStorage.setItem('currentPath', '/upload-fr');
    },
};
</script>

<style lang="scss" scoped>
.btn-delete {
    border: 1px solid #F5222D;
    background-color: #FFE9EA;
    color: #F5222D;
}

.btn-delete:focus {
    border: 1px solid #F5222D;
    background-color: #FFE9EA;
    color: #F5222D;
}

.btn-delete:focus-visible {
    border: 1px solid #F5222D;
    background-color: #FFE9EA;
    color: #F5222D;
}

.btn-delete:hover {
    border: 1px solid #F5222D;
    background-color: #FFE9EA;
    color: #F5222D;
}


.foto-empty {
    width: 232px;
    height: 256px;
    border-radius: 24px;
    background-image: url("../assets/img/selfie-empty.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.foto-filled {
    width: 232px;
    height: 256px;
    border-radius: 24px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    align-self: flex-end;
}

.button-upload {
    font-size: 14px !important;
    padding: 16px 24px !important;
    height: fit-content !important;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.logo-metalgo-footer {
    height: 32px;
}

.card-event {
    background: #F8F8F8;
    border-radius: 16px;
    padding: 4vw;
}

.fr-container {
    padding-left: 4vw;
    padding-right: 4vw;
}

.ticket-identity {
    border-bottom: 1px dashed #D2D7DA
}

.cat-idt {
    color: #647179;
    font-size: 0.75rem;
    font-weight: 400;
}

.card-ticket {
    background-color: #ffffff;
    padding: 4vw;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title-ticket {
    color: #F2F5F7;
    font-family: "Arial Rounded MT" !important;
    letter-spacing: 0.5px;
}

.img-banner-bg {
    background: linear-gradient(103.12deg, rgba(229, 59, 126, 0.96) 7.41%, rgba(255, 115, 115, 0.9269) 92.4%);
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 4vw;
}

.success-message {
    padding-left: 15vw;
    padding-right: 15vw;
}

.success-message img {
    width: 19vw;
    height: 19vw;
}

.card-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
</style>