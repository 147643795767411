var render = function () {
  var _vm$detail, _vm$detail2, _vm$detail3, _vm$detail4, _vm$detail5, _vm$detail6, _vm$detail7, _vm$error, _vm$error2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-page"
  }, [_c('HeaderComp', {
    attrs: {
      "title": "",
      "url": "/check-ticket"
    }
  }), _c('div', {
    staticClass: "card-page img-banner-bg bg-primary h-100"
  }, [_c('div', {}, [_c('div', {
    staticClass: "text-center font-size-20 font-weight-400 title-ticket mb-2"
  }, [_c('p', {
    staticClass: "m-0"
  }, [_vm._v(_vm._s((_vm$detail = _vm.detail) === null || _vm$detail === void 0 ? void 0 : _vm$detail.event_name))])]), _c('div', {
    staticClass: "card-ticket h-100"
  }, [_c('div', {
    staticClass: "ticket-identity"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('p', {
    staticClass: "m-0 cat-idt"
  }, [_vm._v("Nama Pengunjung")]), _c('p', {
    staticClass: "m-0 font-size-14 font-weight-600"
  }, [_vm._v(_vm._s((_vm$detail2 = _vm.detail) === null || _vm$detail2 === void 0 ? void 0 : _vm$detail2.ticket_attendee_name))])]), _c('div', {
    staticClass: "mb-2"
  }, [_c('p', {
    staticClass: "m-0 cat-idt"
  }, [_vm._v("Tiket Kategori")]), _c('p', {
    staticClass: "m-0 font-size-14 font-weight-600"
  }, [_vm._v(_vm._s((_vm$detail3 = _vm.detail) === null || _vm$detail3 === void 0 ? void 0 : _vm$detail3.ticket_category_name))])]), _c('div', {
    staticClass: "mb-2"
  }, [_c('p', {
    staticClass: "m-0 cat-idt"
  }, [_vm._v("Event Schedule")]), _c('p', {
    staticClass: "m-0 font-size-14 font-weight-600"
  }, [_vm._v(_vm._s((_vm$detail4 = _vm.detail) === null || _vm$detail4 === void 0 ? void 0 : _vm$detail4.event_start_date))])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('p', {
    staticClass: "m-0 cat-idt"
  }, [_vm._v("Lokasi")]), _c('p', {
    staticClass: "m-0 font-size-12 font-weight-600"
  }, [_vm._v(_vm._s((_vm$detail5 = _vm.detail) === null || _vm$detail5 === void 0 ? void 0 : _vm$detail5.event_address))])])]), _c('div', {
    staticClass: "center-content text-center fr-container h-100"
  }, [_c('div', {
    staticClass: "font-size-12 font-weight-600 text-black"
  }, [_vm._v("Face Recognition")]), _c('div', {
    staticClass: "font-size-12 font-weight-400 text-black mb-2"
  }, [_vm._v("Upload foto selfie kamu, untuk check in menggunakan Face Recognition")]), _c('button', {
    staticClass: "btn btn-sukha-outline btn-xsm mb-2",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.uploadFr();
      }
    }
  }, [_vm._v("Upload Face Recognition")]), _c('div', {
    staticClass: "font-size-12 font-weight-400 text-black mb-2"
  }, [_vm._v("Atau")]), _c('div', {
    staticClass: "font-size-12 font-weight-600 text-black"
  }, [_vm._v("Scan QR")]), _c('div', {
    staticClass: "font-size-12 font-weight-400 text-black mb-3"
  }, [_vm._v("Scan QR berikut untuk melakukan Check In")]), _c('img', {
    staticClass: "img-qr",
    attrs: {
      "src": (_vm$detail6 = _vm.detail) === null || _vm$detail6 === void 0 ? void 0 : _vm$detail6.qr_code_string
    }
  }), _c('div', {
    staticClass: "font-size-12 font-weight-400 text-black mt-3"
  }, [_vm._v("Nomor Tiket")]), _c('div', {
    staticClass: "font-size-16 font-weight-600 text-black"
  }, [_vm._v(_vm._s((_vm$detail7 = _vm.detail) === null || _vm$detail7 === void 0 ? void 0 : _vm$detail7.ticket_code))])])])])]), _c('b-modal', {
    ref: "failed-modal",
    attrs: {
      "size": "sm",
      "id": "failed-modal",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "warning-icon mb-2",
    attrs: {
      "src": require("../assets/img/warning-icon.png")
    }
  }), _c('p', {
    staticClass: "font-size-20 font-weight-600 mb-2"
  }, [_vm._v(_vm._s((_vm$error = _vm.error) === null || _vm$error === void 0 ? void 0 : _vm$error.title))]), _c('p', {
    staticClass: "font-size-12 font-weight-400 mb-5"
  }, [_vm._v(_vm._s((_vm$error2 = _vm.error) === null || _vm$error2 === void 0 ? void 0 : _vm$error2.message))]), _c('button', {
    staticClass: "btn btn-primary btn-sm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('failed-modal');
      }
    }
  }, [_vm._v("Ok, Terima Kasih")])])]), _c('Loading', {
    attrs: {
      "isLoading": _vm.loading
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }